import React from "react";
import { ICheckParkingRight } from "../ParkingRightsGrid";
import Data from "../../DataRowCol/DataRowCol";
import CustomCheckbox from "../../../../ui/CustomCheckbox/CustomCheckbox";
import { Box } from "@material-ui/core";
import { DirectionsCarOutlined, ErrorOutline } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import styled from "styled-components";
import { variables } from "../../../../theme/variables";
import {isUndefined} from "underscore"
import { selectUseBulkVehicleAssignments, useGetOccupiedParkingRightsCount } from "../../../../containers/FleetManager/MyProducts/ParkingRightsDataTable/common";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";

const { colors } = variables;

const StyledErrorOutline = styled(ErrorOutline)`
  fill: ${colors.red.hex};
  width: 20px;
`;

interface IGridBodyProps {
  loading: boolean;
  state: ICheckParkingRight[];
  onChecked?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disableEditing?: boolean;
}

const GridBody = (props: IGridBodyProps) => {
  const getTotalOccupiedParkingRights = useGetOccupiedParkingRightsCount();
  const { t } = useTranslation(["vehicleAssignment"]);
  const useBulkVehicleAssignments = useAppSelector(selectUseBulkVehicleAssignments);
  const capacityIsExceeded = (pr: ICheckParkingRight) => !isUndefined(pr.right) && (getTotalOccupiedParkingRights(pr.right) > pr.right.totalParkingRights!);
  const hasUnlimitedEntryRights = (pr: ICheckParkingRight) => pr.right?.hasUnlimitedEntryRights && useBulkVehicleAssignments;

  if (!props.loading && props.state) {
    return (
      <>
        {props.state.map((pr: ICheckParkingRight, index: number) => (
          <Data.Row disabled={pr.disabled} key={`${pr.right?.pmcName}_${index}`}>
            <Data.Col xs={8}>
              {!props.disableEditing && <CustomCheckbox
                label={`${pr.right?.location} - ${pr.right?.pmcName}`}
                checkboxProps={{
                  id: `${pr.right?.pmcId}`,
                  name: `rights[${index}]`,
                  value: pr.right?.pmcId,
                  checked: pr.checked,
                  onChange: props.onChecked,
                  disabled: pr.disabled,
                }}
              />}
              {props.disableEditing && 
              <span>{`${pr.right?.location} - ${pr.right?.pmcName}`}</span>}
            </Data.Col>
            <Data.Col xs={4}>
              <Box component="div" className="d-flex row-wrap justify-right align-center">
                <Box component="span" className="available-count">
                  {capacityIsExceeded(pr) && !hasUnlimitedEntryRights(pr) && (<StyledErrorOutline />)}
                  {`${pr.right && getTotalOccupiedParkingRights(pr.right)} / ${hasUnlimitedEntryRights(pr) ? t("unlimited") : pr.right?.totalParkingRights}`}
                </Box>
                &nbsp;
                <DirectionsCarOutlined classes={{ root: "grayish-blue large-important" }} />
              </Box>
            </Data.Col>
          </Data.Row>
        ))}
      </>
    );
  } else {
    return (
      <>
        {[1, 2, 3, 4].map((skeleton) => (
          <SkeletonGrid key={skeleton} />
        ))}
      </>
    );
  }
};

const SkeletonGrid = () => (
  <Data.Row disabled={false}>
    <Data.Col xs={8}>
      <Skeleton animation="wave" height={25} />
    </Data.Col>
    <Data.Col xs={2}>
      <></>
    </Data.Col>
    <Data.Col xs={2}>
      <Skeleton animation="wave" height={25} />
    </Data.Col>
  </Data.Row>
);

export default GridBody;
