import axios from "axios";
import { isEmpty } from "underscore";
import storageService from "./services/storage.service";

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const storedToken = storageService.getTokenFromStorage();

  if (!isEmpty(storedToken)) {
    config.headers!.Authorization = `Bearer ${storedToken}`;
  }

  return config;
});

export default instance;
