type NumberPlateValidator = {
  isValidNumberPlatePrefix: (value: string) => boolean;
  isValidNumberPlate: (value: string) => boolean;
  isValidGermanNumberPlate: (value: string) => boolean;
  isValidGermanRegionNumber: (value: string) => boolean;
  isValidNumberPlateWithoutDash: (value: string) => boolean;
};

const numberPlateValidator: NumberPlateValidator = (() => {
  const numberPlatePrefixPattern = /^[A-Z]{1,3}$/;
  const numberPlatePattern = /^(([A-Za-z0-9ÆØæø]+-)?[A-Za-z0-9ÆØæø]+)?$/;
  const numberPlatePatternWithoutDash = /^(([A-Za-z0-9ÆØæø]+)?[A-Za-z0-9ÆØæø]+)?$/;
  const germanNumberPlatePattern = /^([A-Za-z0-9ÖÜÄöüä]{1,3}-[A-Za-z0-9]+)?$/;
  const germanRegionPattern = /^([A-Za-z0-9ÖÜÄöüä]{1,3})?$/;

  const isValidNumberPlatePrefix = (value: string): boolean =>
    numberPlatePrefixPattern.test(value);

  const isValidNumberPlate = (value: string): boolean =>
    numberPlatePattern.test(value);

  const isValidGermanNumberPlate = (value: string): boolean =>
    germanNumberPlatePattern.test(value);

  const isValidGermanRegionNumber = (value: string): boolean =>
    germanRegionPattern.test(value);

  const isValidNumberPlateWithoutDash = (value: string): boolean =>
    numberPlatePatternWithoutDash.test(value);

  return {
    isValidNumberPlatePrefix,
    isValidNumberPlate,
    isValidGermanNumberPlate,
    isValidGermanRegionNumber,
    isValidNumberPlateWithoutDash,
  };
})();

export default numberPlateValidator;
