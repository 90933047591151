import "../../../scss/_uploadView.scss";
import React from "react";
import { Container, Button, Stepper, Step, StepLabel } from "@material-ui/core";
import Heading from "../../../ui/Heading/Heading";
import { variables } from "../../../theme/variables";
import { CloseOutlined } from "@material-ui/icons";
import CustomButton from "../../../ui/CustomButton/CustomButton";
import useFleetManagerContext from "../../../context/hooks/useFleetManagerContext";
import { useTranslation } from "react-i18next";
import ActionDrawer from "../../../ui/ActionDrawer/ActionDrawer";
import CloseImportWarning from "../../Warnings/CloseImportWarning";
import VehiclesImportDragAndDrop from "../VehiclesImportDragAndDrop/VehiclesImportDragAndDrop";
import useSmartState from "../../../utils/hooks/useSmartState";
import useImportVehiclesContext from "../../../context/hooks/useImportVehiclesContext";
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";
import { useAppDispatch, useAppSelector } from "../../../store";
import useAppContext from "../../../context/hooks/useAppContext";
import BulkVehicleProgressView from "../BulkVehicleProgressView/BulkVehicleProgressView";
import { AssignmentType } from "../../../context/store/reducers/fleetManagerContext.reducer";

const { typography } = variables;
interface IFormProps {
  onRefresh?: () => void;
}

const MultipleVehiclesUploadView: React.FC<IFormProps> = (props) => {
  const { t } = useTranslation(["multipleVehicleForm"]);
  const dispatch = useAppDispatch();
  const parkingProducts = useAppSelector((state) => state.parkingProducts);
  const bulkUploadFinished = useAppSelector((state) => state.parkingProducts.bulkVehicleImportProgress.progress === 100);
  const resetBulkUploadProgress = () => dispatch(parkingProductsActions.resetBulkVehicleImportProgress());

  const {
    fleetManagerState,
    setVehicleDragAndDropView,
    toggleCloseImportWarning: toggleCloseImportWarning,
    closeMultipleVehicles
  } = useFleetManagerContext();

  const isMultipleAssignVehicle = fleetManagerState.assignmentType === AssignmentType.Multiple;

  const closeUploadView = () => {
    setVehicleDragAndDropView(false);
    setVehicleData([]);
  }

  const initialState: VehicleDndState = {
    step: DndStep.FileUpload,
    afterWarningConfirmationAction: closeUploadView,
    showOnlyErrors: true,
    showConfirmationScreen: false,
  };

  const { state, updateState } = useSmartState<VehicleDndState>(initialState);
  const { appState } = useAppContext();
  const {
    importVehiclesState,
    setVehicleData,
    setWhitelistDeltaChanges,
    setProcessing
  } = useImportVehiclesContext();

  const selectedParkingProducts = parkingProducts.locatedParkingProducts.data?.flatMap(l => l.parkingProducts)
    .map(p => ({ pmcId: p.pmcId!, seasonTicketId: p.id!, countryCode: p.countryCode! })) || [];

  const onClose = () => {
    if (shouldShowDataRemovalWarning()) {
      setAfterWarningConfirmationAction(closeUploadView);
      toggleCloseImportWarning();
      return;
    }

    resetBulkUploadProgress();
    closeUploadView();
    if (state.step === DndStep.BulkInProgress) {
      closeMultipleVehicles();
      props.onRefresh && props.onRefresh();
    }
  };

  const shouldShowDataRemovalWarning = () => {
    if (bulkUploadFinished) {
      return false;
    }

    return (
      importVehiclesState.processing ||
      (importVehiclesState.data &&
        importVehiclesState.data.filter(
          (d) => d.country.value !== "" || d.numberPlate.value !== "" || d.description.value !== ""
        ).length > 0)
    );
  };

  const startBulkVehicleAssignment = () => {
    const isWhitelistUpload = parkingProducts.calculatedWhitelistDelta.hasChanges && parkingProducts.calculatedWhitelistDelta.fileReference;
    const isVehicleBulkUpload = parkingProducts.validatedVehicleBulkUpload.amountOfVehiclesToCreate > 0 && parkingProducts.validatedVehicleBulkUpload.fileReference;
    const startBulkVehicleAssignmentRequest = { parkingProducts: selectedParkingProducts, isUnlimited: Boolean(isWhitelistUpload) };

    if (isWhitelistUpload) {
      updateState({ ...state, step: DndStep.BulkInProgress })
      dispatch(parkingProductsActions.startBulkVehicleAssignment(appState.user.seasonTicketOwnerCrmId!, parkingProducts.calculatedWhitelistDelta.fileReference!, startBulkVehicleAssignmentRequest) as any);
    }
    else if (isVehicleBulkUpload) {
      updateState({ ...state, step: DndStep.BulkInProgress })
      dispatch(parkingProductsActions.startBulkVehicleAssignment(appState.user.seasonTicketOwnerCrmId!, parkingProducts.validatedVehicleBulkUpload.fileReference!, startBulkVehicleAssignmentRequest) as any);
    }
  }

  const isStartBulkVehicleAssignmentButtonDisabled = (): boolean => {
    return (
      importVehiclesState.data.length > 0 &&
      importVehiclesState.data.some((d) => !d.country.isValid || !d.numberPlate.isValid || !d.description.isValid)
    ) || state.step === DndStep.BulkInProgress || !importVehiclesState.hasWhitelistChanges;
  }

  const setAfterWarningConfirmationAction = (
    afterWarningConfirmationAction: () => void
  ) => updateState({ afterWarningConfirmationAction });

  return (
      <div className="upload-view-dnd-container">
        <div className="upload-view-dnd-header">
          <Container className="upload-view-dnd-header-content">
            <Heading
              width="auto"
              justifyContent="center"
              fontSize={typography.fontSizeLarge}
            >
              {isMultipleAssignVehicle ? t("assignMultipleVehicles") : t("whitelistVehicles")}
            </Heading>
            <Button
              onClick={onClose}
              classes={{ root: "close-icon-btn" }}
              disableRipple
            >
              <CloseOutlined classes={{ root: "close-icon" }} />
            </Button>
          </Container>
          <Container className="upload-view-dnd-header-stepper">
            <Stepper className="steps" color="#8bb611">
              <Step
                active={state.step === DndStep.FileUpload}
                completed={state.step! > DndStep.FileUpload}
              >
                <StepLabel />
              </Step>
              {(
                <Step
                active={state.step === DndStep.BulkInProgress}
                completed={state.step === DndStep.BulkInProgress && bulkUploadFinished}
                >
                  <StepLabel />
                </Step>
              )
              }
            </Stepper>
          </Container>
        </div>
        <div className="upload-view-dnd-main">
          <div className="h-full">
            <VehiclesImportDragAndDrop
              show={state.step === DndStep.FileUpload}
              onFileProcessed={(data) => setVehicleData(data)}
              onChanges={(onChanges) => setWhitelistDeltaChanges(onChanges)}
              onProcessing={(processing) => setProcessing(processing)}
            />
            {
              state.step === DndStep.BulkInProgress &&
              (<BulkVehicleProgressView />)
            }
          </div>
        </div>
        <div className="upload-view-dnd-footer">
          <Container className="upload-view-dnd-footer-content">
          <CustomButton.Light onClick={onClose} text={bulkUploadFinished ? t("globals:close") : t("backToSelection")} id="close-invite" />
            {(
              <CustomButton.Primary
                onClick={() => startBulkVehicleAssignment()}
                text={isMultipleAssignVehicle ? t("addVehicles") : t("whitelistVehicles")}
                disabled={ isStartBulkVehicleAssignmentButtonDisabled() }
              />
            )}
          </Container>
        </div>
        <ActionDrawer
          open={fleetManagerState.showCloseImportWarning}
          onClose={toggleCloseImportWarning}
          hideBackdrop={true}
        >
          <CloseImportWarning
            onConfirmation={state.afterWarningConfirmationAction ?? onClose}
          />
        </ActionDrawer>
      </div>
  );
};

enum DndStep {
  FileUpload = 0,
  BulkInProgress = 1,
}

interface VehicleDndState {
  step?: DndStep;
  afterWarningConfirmationAction?: () => void;
  showOnlyErrors?: boolean;
  showConfirmationScreen?: boolean;
}

export default MultipleVehiclesUploadView;
