import React, { Dispatch, useState } from "react";
import { Container } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import { NoPaddingBottomGrid } from "../../DataTable/DataTableStyledComponents";
import Heading from "../../../ui/Heading/Heading";
import { variables } from "../../../theme/variables";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { isEmpty } from "underscore";
import { connect } from "react-redux";
import useAppContext from "../../../context/hooks/useAppContext";
import { VehicleData } from "../../../context/store/reducers/importVehicles.reducer";
import useImportVehiclesContext from "../../../context/hooks/useImportVehiclesContext";
import VehicleDragAndDrop, { DndError, DndState, VehicleBulkUpload, whitelistDelta } from "../VehicleDragAndDrop/VehicleDragAndDrop";
import { getValidationErrorMessage, validateFile } from "./ValidateVehicleBulkFile";
import { CalculatedWhitelistDeltaResponse, CalculateWhitelistDeltaRequest, VehicleInfo } from "../../../models/vehicle-assignments/CalculateWhitelistDelta";
import { convertNumberPlatePrefixToIsoCountryCode } from "../../../lib/countriesNumberPlates";
import useFleetManagerContext from "../../../context/hooks/useFleetManagerContext";
import { AssignmentType } from "../../../context/store/reducers/fleetManagerContext.reducer";
import { ValidatedVehicleBulkUploadResponse, ValidateVehicleBulkUploadRequest } from "../../../models/vehicle-assignments/ValidateVehicleBulkUpload";
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";

const { typography } = variables;

const dprSheetName = "Import Vehicles";
const countryName = "Country";
const numberPlateName = "Number Plate";
const descriptionName = "Description";
const rowNumber = "__rowNum__";

const VehiclesImportDragAndDrop: React.FC<VehiclesImportDragAndDropProps> = (
  props
) => {
  const [state, setState] = useState<DndState>("default");
  const [error, setError] = useState<DndError | undefined>(undefined);
  const [invalidVehicleCount, setInvalidVehicleCount] = useState<number>();
  const [fileReference, setFileReference] = useState<string>();
  const [whitelistDelta, setWhitelistDelta] = useState<whitelistDelta | undefined>(undefined);
  const [vehicleBulkUpload, setVehicleBulkUpload] = useState<VehicleBulkUpload | undefined>(undefined);
  const [validateVehicles, setValidateVehicles] = useState<VehicleData[]>([]);
  const { appState } = useAppContext();

  const { importVehiclesState } = useImportVehiclesContext();
  const { fleetManagerState } = useFleetManagerContext();
  const isMultipleAssignVehicle = fleetManagerState.assignmentType === AssignmentType.Multiple;

  const { t } = useTranslation(["multipleVehicleForm"]);
  const supportedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const handleFileUpload = (file: File) => {
    setState("processing");
    props.onProcessing(true);
    readVehiclesFromXls(file);
  };

  const readVehiclesFromXls = (file: File) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = handleUploadedData;
  };

  const handleUploadedData = (e: ProgressEvent<FileReader>) => {
    const vehiclesToImport: VehicleData[] = [];

    const data = e.target?.result;
    const wb = XLSX.read(data, { type: "binary" });

    if (wb.SheetNames && wb.SheetNames.includes(dprSheetName)) {
      readDataFromExcel(wb, vehiclesToImport);

      const products = [...importVehiclesState.products];
      const countValidationErrors = validateFile(vehiclesToImport);
      setValidateVehicles(vehiclesToImport);
      props.onFileProcessed(vehiclesToImport);

      if (countValidationErrors === 0) {
        if (isMultipleAssignVehicle) {
          validateVehicleBulkUpload(products, vehiclesToImport);
        } else {
          calculateWhiteListDelta(products, vehiclesToImport);
        }
      }
      else {
        setState("error");
        setError("invalid");
        setInvalidVehicleCount(countValidationErrors);
      }
    } else {
      setState("error");
      setError("invalidFile");
    }
  };

  const validateVehicleBulkUpload = (products: number[], vehiclesToImport: VehicleData[]) => {
    const req: ValidateVehicleBulkUploadRequest = {
      seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
      pmcIds: products,
      vehicles: vehiclesToImport.map(v => ({
        value: v.numberPlate.value,
        countryCode: convertNumberPlatePrefixToIsoCountryCode(v.country.value.toUpperCase()),
        description: v.description.value
      } as VehicleInfo) )
    };

    const fileIsEmpty = isEmpty(vehiclesToImport);
    if (fileIsEmpty) {
      setState("error");
      setError("empty");
      return;
    }

    props.validateVehicleBulkUpload(
      req,
      (data) => {
        props.onProcessing(false);
        setVehicleBulkUpload({
          amountOfExceedingVehicles: data.amountOfExceedingVehicles,
          amountOfVehiclesToCreate: data.amountOfVehiclesToCreate
        });
        setFileReference(data.fileReference);

        if (data.amountOfExceedingVehicles != 0) {
          setState("error");
          setError("amountOfExceedingVehicles");
        } else {
          props.onChanges(data.amountOfVehiclesToCreate > 0);
          setState("success");          
        }
      },
      () => {
        setState("error");
        setError("unexpected");
      }
    )
  };

  const calculateWhiteListDelta = (products: number[], vehiclesToImport: VehicleData[]) => {
    const req: CalculateWhitelistDeltaRequest = {
      seasonTicketOwnerCrmId: appState.user.seasonTicketOwnerCrmId as string,
      pmcIds: products,
      vehicles: vehiclesToImport.map(v => ({
        value: v.numberPlate.value,
        countryCode: convertNumberPlatePrefixToIsoCountryCode(v.country.value.toUpperCase()),
        description: v.description.value
      } as VehicleInfo) )
    };

    props.calculateWhitelistDelta(
      req,
      (data) => {

        const delta: whitelistDelta = {
          amountOfVehiclesToCreate: data.amountOfVehiclesToCreate,
          amountOfVehiclesToDelete: data.amountOfVehiclesToDelete,
          amountOfVehiclesToUpdate: data.amountOfVehiclesToUpdate,
          amountOfVehiclesWithNoChanges: data.amountOfVehiclesWithNoChanges,
          hasChanges: data.hasChanges
        };

        props.onProcessing(false);
        props.onChanges(delta.hasChanges);
        setFileReference(data.fileReference);
        setWhitelistDelta(delta);

        const fileIsEmpty = isEmpty(vehiclesToImport);
        if (fileIsEmpty) {
          setState("error");
          setError("empty");
        }else{
          setState("success");
        }
      },
      (errorCode) => {
        setState("error");
        setError("unexpected");
      });
  }

  const readDataFromExcel = (wb: XLSX.WorkBook, vehiclesToImport: VehicleData[]) => {
    const rows: any[] = XLSX.utils.sheet_to_json(wb.Sheets[dprSheetName]);
    rows.forEach((row) => {
      if (row[countryName] || row[numberPlateName] || row[descriptionName]) {
        let vehicle: VehicleData;
        vehicle = {
          country: { value: convertFileValueToString(row[countryName]).toLowerCase(), isValid: true },
          numberPlate: { value: convertFileValueToString(row[numberPlateName]).toUpperCase(), isValid: true },
          description: { value: convertFileValueToString(row[descriptionName]), isValid: true },
          lineNumber: row[rowNumber] + 1
        };
        vehiclesToImport.push(vehicle);
      }
    });
  }

  const convertFileValueToString = (value: any): string => {
    if(typeof value === 'number'){
      return String(value);
    }

    if(typeof value === 'undefined' || value === null || isEmpty(value)){
      return '';
    }

    return String(value);
  }

  const createVehicleErrorFileData = (vehicleToImport: VehicleData[]) => {
    const errorData: VehicleErrorData[] = [];

    vehicleToImport.forEach(x => {
      if(!x.country.isValid){
        errorData.push(mapVehicleErrorData(x, getValidationErrorMessage(x.country.errorMessageKey!, t)))
      }

      if(!x.description.isValid){
        errorData.push(mapVehicleErrorData(x, getValidationErrorMessage(x.description.errorMessageKey!, t)))
      }

      if(!x.numberPlate.isValid){
        errorData.push(mapVehicleErrorData(x, getValidationErrorMessage(x.numberPlate.errorMessageKey!, t)))
      }
    });

    return errorData;
  }

  const mapVehicleErrorData = (vehicleToImport: VehicleData, errorMessage: string)  => {
    const error: VehicleErrorData = {
      country: vehicleToImport.country.value,
      numberPlate: vehicleToImport.numberPlate.value,
      description: vehicleToImport.description.value,
      errorMessage: errorMessage
    };

    return error;
  }

  const handleDownloadFile = () => {
    const errorData = createVehicleErrorFileData(validateVehicles);
    const csvData = createCsvFile(errorData);

    // Add encoding for deserializing punctuation marks correctly, ex: dÃ©jÃ -> déjà
    const url = URL.createObjectURL(new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]),csvData],{ type: "text/plain;charset=utf-8" }));
    const link = document.createElement("a");
    link.href = url;
    const date = new Date();
    const dateValue =  `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}-${date.getHours()}${date.getMinutes()}`;
    link.download = `validationNP_errors-${dateValue}.csv`;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  const createCsvFile = (data: VehicleErrorData[]) => {
    let csvRows: string[] = [];

    const headers = Object.keys(data[0]);

    csvRows.push(headers.map(header => t(header)).join(','));

    data.forEach(x => {
      const values = Object.values(x).join(',');
      csvRows.push(values);
    });

    return csvRows.join('\n');
  }


  if (!props.show) {
    return null;
  }

  return (
    <React.Fragment>
      {error === undefined && (<Container>
        <NoPaddingBottomGrid container item direction="row" alignItems="center">
          <NoPaddingBottomGrid container item direction="column" xs={6}>
            <Heading fontSize={typography.fontSizeBase}>
              <strong>{t("uploadFile")}</strong>
            </Heading>
          </NoPaddingBottomGrid>
          <NoPaddingBottomGrid container item direction="column" xs={6}>
            <div className="download-template">
              <div>
                <a
                  href="/files/VehicleImportTemplate.xlsx"
                  target="_blank"
                  download
                >
                  {t("downloadTemplate")}
                </a>
                <KeyboardArrowDown />
              </div>
            </div>
          </NoPaddingBottomGrid>
        </NoPaddingBottomGrid>
      </Container>)}
      <Container className="h-full">
        <VehicleDragAndDrop
          onFileUploaded={handleFileUpload}
          onDownloadErrorFile={handleDownloadFile}
          supportedFileTypes={supportedFileTypes}
          state={state}
          error={error}
          whitelistDelta={whitelistDelta}
          vehicleBulkUpload={vehicleBulkUpload}
          seasonTicketOwnerCrmId={appState.user.seasonTicketOwnerCrmId as string}
          invalidVehicleCount={invalidVehicleCount}
        />
      </Container>
    </React.Fragment>
  );
};

export type VehicleErrorData = {
  country: string;
  numberPlate: string;
  description: string;
  errorMessage: string;
}


const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  calculateWhitelistDelta: (
    req: CalculateWhitelistDeltaRequest,
    onSuccess: (response: CalculatedWhitelistDeltaResponse) => void,
    onError: (errorCode: string) => void) =>
    dispatch(parkingProductsActions.calculateWhitelistDelta(req, onSuccess, onError)),
  validateVehicleBulkUpload: (
    req: ValidateVehicleBulkUploadRequest,
    onSuccess: (response: ValidatedVehicleBulkUploadResponse) => void,
    onError: (error: string) => void) =>
    dispatch(parkingProductsActions.validateVehicleBulkUpload(req, onSuccess, onError))
});

interface VehiclesImportDragAndDropProps extends DispatchProps {
  show: boolean;
  onFileProcessed: (data: VehicleData[]) => void;
  onChanges: (hasChanges: boolean) => void;
  onProcessing: (processing: boolean) => void;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(VehiclesImportDragAndDrop);
