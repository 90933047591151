import React, { Dispatch, useEffect } from "react";
import { Formik } from "formik";
import { Container, Button, Box } from "@material-ui/core";
import { Translation, useTranslation } from "react-i18next";
import { CloseOutlined } from "@material-ui/icons";
import { variables } from "../../theme/variables";
import { without } from "underscore";
import Heading from "../../ui/Heading/Heading";
import CustomButton from "../../ui/CustomButton/CustomButton";
import ParkingRightsGrid from "../DelegeeForm/ParkingRightsGrid/ParkingRightsGrid";
import { RootReducer } from "../../store/reducers";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import { connect } from "react-redux";
import useAppContext from "../../context/hooks/useAppContext";
import useValidationSchema, { IWhitelistVehiclesFormValues } from "./hooks/useValidationSchema";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import useImportVehiclesContext from "../../context/hooks/useImportVehiclesContext";
import WarningColumn from "../../ui/CenteredWarning/CenteredWarning";
import "./_multipleVehiclesForm.scss";

const { typography } = variables;
interface IFormProps extends StateProps, DispatchProps {
  onClose: () => void;
  onRefresh?: () => void;
}

enum Fields {
  Products = "products"
}

const MultipleVehiclesForm: React.FC<IFormProps> = (props) => {
  const {
    onClose,
    parkingProducts,
    getUnlimitedParkingProducts,
    disposeError,
    getBulkVehicleAssignmentsStatus,
  } = props;

  const { appState } = useAppContext();
  const { setVehicleDragAndDropView } =
  useFleetManagerContext();
  const {
    setSelectedProducts,
  } = useImportVehiclesContext();

  const { t } = useTranslation(["fleetManagerTable"]);

  useEffect(() => {
    return () => disposeError();
  },  [disposeError]);

  const handleFormSubmit = (values: IWhitelistVehiclesFormValues) => {
    setSelectedProducts(values.products!);
    goToUploadFile();
  };

  const { validationSchema, formik } = useValidationSchema(
    t,
    handleFormSubmit
  );

  const fetchParkingProducts = () => {
    getUnlimitedParkingProducts(appState.user.seasonTicketOwnerCrmId as string, appState.selectedLanguage);
  };

  const fetchBulkVehicleAssignmentsStatus = () => {
    getBulkVehicleAssignmentsStatus(appState.user.seasonTicketOwnerCrmId as string);
  }

  const initForm = () => {
    fetchParkingProducts();
    fetchBulkVehicleAssignmentsStatus();
  };

  useEffect(() => {
    initForm();
  }, []);

  const handleSelectAll = (parkingProducts: number[]) => {
    formik.setFieldValue(Fields.Products, parkingProducts);
  };

  const handleDeselectAll = () => {
    formik.setFieldValue(Fields.Products, []);
  };

  const handleProductChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    parkingProducts: number[]
  ) => {
    const { value, checked } = event.currentTarget;
    const productId = parseInt(value);

    let products = [...parkingProducts];
    if (!checked) {
      products = without(products, productId);
    } else {
      products.push(productId);
    }
    formik.setFieldValue(Fields.Products, products);
  };

  const getFormTitle = () => t("whitelistVehicles");
  const submitButtonText = () => t("delegeeForm:goToUpload")
  const goToUploadFile = (): void => setVehicleDragAndDropView(true);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={formik.initialValues}
      onSubmit={handleFormSubmit}
    >
        <div className="form-container">
          <div className="form-header">
            <Container className="form-header-content">
              <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                {getFormTitle()}
              </Heading>
              <Button onClick={onClose} classes={{ root: "close-icon-btn" }} disableRipple>
                <CloseOutlined classes={{ root: "close-icon" }} />
              </Button>
            </Container>
          </div>
          <Box component="form" sx={{ flexGrow: 1, paddingY: 2 }}>
            <div className="form-body">
              <Container>
                <Translation ns={["vehicleForm", "delegeeForm"]} nsMode="fallback">
                  {(t) => (
                   <>
                    <ParkingRightsGrid
                      data={parkingProducts.parkingProducts.data!}
                      loading={parkingProducts.parkingProducts.loading}
                      onChange={(event) => handleProductChange(event, formik.values.products ?? [])}
                      onStateChange={prs => handleSelectAll(prs.filter(pr => pr.checked).map(pr => pr.right?.pmcId!))}
                      onSelectAll={(ids) => handleSelectAll(ids)}
                      onDeselectAll={handleDeselectAll}
                      error={{
                        hasError: Boolean(formik.errors.products),
                        message: formik.errors.products,
                      }}
                      title={t("selectProduct")}
                      required={true}
                      selectAllTooltipText={t("selectTooltip")}
                      deselectAllTooltipText={t("deselectTooltip")}
                      selectAllText={t("selectAll")}
                      deselectAllText={t("deselectAll")}
                    />

                   {!parkingProducts.isBulkVehicleAssignmentsCompleted && (
                        <div className="bulkimport-inprogress-warning">
                            <WarningColumn
                                title={t("bulkImportInProgressWarningTitle")}
                                details={t("bulkImportInProgressWarningDescription")}
                                orangeWarning={true}
                            />
                        </div>
                    )}

                  </>
                  )}
                </Translation>

              </Container>
            </div>
          </Box>
          <div className="form-footer">
            <Container className="form-footer-content">
              <React.Fragment>
                <CustomButton.Light onClick={onClose} text={t("globals:close")} id="close-invite" />
                <CustomButton.Primary
                  loading={parkingProducts.loading}
                  onClick={(e) => {
                    e?.preventDefault();
                    formik.handleSubmit();
                  }}
                  text={submitButtonText()}
                  id="submit-invite"
                  disabled={!parkingProducts.isBulkVehicleAssignmentsCompleted}
                />
              </React.Fragment>
            </Container>
          </div>
        </div>
    </Formik>
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts } = state;

  return {
    parkingProducts
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  disposeError: () => dispatch(parkingProductsActions.disposeError()),
  getUnlimitedParkingProducts: (seasonTicketOwnerCrmId: string, location: string,) => dispatch(parkingProductsActions.getUnlimitedParkingProducts(seasonTicketOwnerCrmId, location)),
  getBulkVehicleAssignmentsStatus: (seasonTicketOwnerCrmId: string) => dispatch(parkingProductsActions.getBulkVehicleAssignmentsStatus(seasonTicketOwnerCrmId)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MultipleVehiclesForm);
