import { createStore, applyMiddleware, compose, Dispatch, AnyAction } from "redux";
import rootReducer from "./reducers";
import { history, middlewares } from "./buildEnv";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { type ThunkAction  } from "redux-thunk";

const composeEnhancers : typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer(history), composeEnhancers(applyMiddleware(...middlewares)));

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<(action: AnyAction | ThunkAction<any, RootState, any, AnyAction>) => void>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;