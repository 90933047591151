import { VehicleData } from "../../../context/store/reducers/importVehicles.reducer";
import numberPlateValidator from "../../../validators/numberplate.validator";
import { CountriesNumberPlates } from "../../../lib/countriesNumberPlates"
import { isEmpty } from "underscore";
import { vehicleErrorType } from "../../../context/store/reducers/importVehicles.reducer";
import { TFunction } from "i18next";
  
export const validateFile = (vehicles: VehicleData[]): number => {
    validateDuplicateNumberPlate(vehicles);
    vehicles.forEach(validateVehicle);

    return countVehicleErrors(vehicles);
}

export const getValidationErrorMessage = (vehicleErrorType: vehicleErrorType, t: TFunction): string => {
    const vehicleErrorMessages = {
        "invalidDescriptionLength":  t("invalidDescriptionLengthMessage"),
        "invalidGermanNumberPlate":  t("invalidGermanNumberPlateMessage"),
        "invalidNumberPlate":  t("invalidNumberPlateMessage"),
        "invalidCountryCode":  t("invalidCountryCodeMessage"),
        "missingNumberPlate":  t("missingNumberPlateMessage"),
        "missingCountryCode":  t("missingCountryCodeMessage"),
        "duplicatedNumberPlate":  t("duplicatedNumberPlateMessage")
    };

    return vehicleErrorMessages[vehicleErrorType];
}

const validateVehicle = (vehicle: VehicleData) => {
   validateCountry(vehicle);
   validateNumberplate(vehicle);
   validateDescription(vehicle);
}

const validateDuplicateNumberPlate = (vehicles: VehicleData[]) => {
    var vehicleCount : Record<string, number> = {};
    vehicles.forEach(v => { 
        if(!isEmpty(v.country.value) && !isEmpty(v.numberPlate.value)){
            const key = getVehicleKey(v.country.value, v.numberPlate.value);
            vehicleCount[key] = (vehicleCount[key]||0) + 1;
        }
    });

    vehicles.forEach(v => {
        const key = getVehicleKey(v.country.value, v.numberPlate.value);
        const hasDuplicate = vehicleCount[key] > 1;

        if(hasDuplicate){
            v.numberPlate.isValid = false;
            v.numberPlate.errorMessageKey = "duplicatedNumberPlate";
        }
    });
};

const getVehicleKey = (countryCode: string, numberplate: string): string => {
    return countryCode?.toLowerCase() + "." + numberplate?.toLowerCase();
}

const validateCountry = (vehicle: VehicleData) => {
    const country = vehicle.country.value;

    if(isEmpty(country)){
        vehicle.country.isValid = false;
        vehicle.country.errorMessageKey = "missingCountryCode";
    } else {
        vehicle.country.isValid = new CountriesNumberPlates().isValidNumberPlateCode(country);
        vehicle.country.errorMessageKey = vehicle.country.isValid ? undefined : "invalidCountryCode";
    }
}

const validateNumberplate = (vehicle: VehicleData) => {
    const country = vehicle.country.value;
    const numberPlate = vehicle.numberPlate.value;

    if(isEmpty(numberPlate)){
        vehicle.numberPlate.isValid = false;
        vehicle.numberPlate.errorMessageKey = "missingNumberPlate";
    } else if(vehicle.numberPlate.isValid) {
        if(isGermanCountry(country)){
            vehicle.numberPlate.isValid = numberPlateValidator.isValidGermanNumberPlate(numberPlate);
            vehicle.numberPlate.errorMessageKey = vehicle.numberPlate.isValid ? undefined : "invalidGermanNumberPlate";
        } else {
            vehicle.numberPlate.isValid = numberPlateValidator.isValidNumberPlateWithoutDash(numberPlate);
            vehicle.numberPlate.errorMessageKey = vehicle.numberPlate.isValid ? undefined : "invalidNumberPlate";
        }
    }
}

const validateDescription = (vehicle: VehicleData) => {
    const isValidDescription = vehicle.description.value === undefined || vehicle.description.value.length <= 100;
    if (!isValidDescription){
        vehicle.description.errorMessageKey = "invalidDescriptionLength";
        vehicle.description.isValid = false;
        vehicle.description.value = `"${vehicle.description.value.replaceAll('"', '""')}"`;
    }
}

const countVehicleErrors = (vehicles: VehicleData[]): number => {
    return vehicles.reduce((amount, vehicle) => {
        amount += vehicle.country.isValid ? 0 : 1;
        amount += vehicle.description.isValid ? 0 : 1;
        amount += vehicle.numberPlate.isValid ? 0 : 1;

        return amount;
    }, 0);
}

const isGermanCountry = (country: string) => {
    return !isEmpty(country) && country.toLowerCase() === "d"
}
  