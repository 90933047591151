import { Box, Container, Grid, LinearProgress, Typography } from "@material-ui/core";
import React, { Dispatch, useEffect } from "react";
import { Trans, Translation, useTranslation } from "react-i18next";
import CircleImage from "../../../ui/CircleImage/CircleImage";
import XlsFileLoading from "../../../assets/images/xls-file-loading.svg"
import { NoPaddingBottomGrid } from "../../DataTable/DataTableStyledComponents";
import Heading from "../../../ui/Heading/Heading";
import { variables } from "../../../theme/variables";
import "../../../scss/_bulkProgressView.scss"
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";
import { connect } from "react-redux";
import { RootReducer } from "../../../store/reducers";
import useAppContext from "../../../context/hooks/useAppContext";
import { useAppSelector } from "../../../store";
import { EnvironmentState } from "../../../store/reducers/environment.reducer";

const BulkVehicleProgressView: React.FC<BulkVehicleImportViewProps> = ({
  calculatedWhitelistDelta,
  bulkVehicleImportProgress,
  bulkVehicleImportPollingIntervalInSeconds,
  getBulkVehicleImportProgress
}) => {
  const { typography } = variables;
  const { t } = useTranslation(["multipleVehicleForm"]);
  const { appState } = useAppContext();
  const parkingProducts = useAppSelector((state) => state.parkingProducts);

  const bulkUploadIsFinished = () => {
    return bulkVehicleImportProgress.progress === 100;
  }

  const pollBulkVehicleImportProgress = () => {
    setTimeout(() => {
      const fileReference = calculatedWhitelistDelta.fileReference! == "" ? parkingProducts.validatedVehicleBulkUpload.fileReference : calculatedWhitelistDelta.fileReference;
      getBulkVehicleImportProgress(appState.user.seasonTicketOwnerCrmId!, fileReference, pollBulkVehicleImportProgress)
    }, bulkVehicleImportPollingIntervalInSeconds * 1000)
  }

  useEffect(() => {
    pollBulkVehicleImportProgress();
  }, [])

  const getBulkUploadTitleKey = () => {
    if (bulkUploadIsFinished()) {
      if (parkingProducts.hasUnlimitedEntryRights) {
        return "fileProcessed"
      }
      return "allVehiclesAssigned"
    } else {
      return "assignVehiclesInProgress"
    }
  }

  const getBulkUploadMessageKey = () => {
    if (bulkUploadIsFinished()) {
      if (parkingProducts.hasUnlimitedEntryRights) {
        return "closeScreen"
      }
      return "allVehiclesAssignedMessage"
    } else {
      return "assignVehiclesInProgressMessage"
    }
  }

  return (
    <Container id="bulkVehicleProgress" className="h-full">
      <div>
        <NoPaddingBottomGrid
          container
          item
          direction="column"
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <div className="img-wrapper">
            <CircleImage src={XlsFileLoading} />
          </div>
        </NoPaddingBottomGrid>
        <Grid
          container
          item
          direction="column"
          xs={12}
          alignItems="center"
          justifyContent="center"
          className="p-t-2"
        >
          <Translation ns="multipleVehicleForm">
            {t => (
              <div className="dnd-file-choose-label">
                <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                  {<Trans t={t} i18nKey={getBulkUploadTitleKey()} components={{ br: <br /> }} />}
                </Heading>
                <span className="text-dark-gray-2nd text-center d-flex justify-center">
                  {<Trans t={t} i18nKey={getBulkUploadMessageKey()} components={{ br: <br /> }} />}
                </span>
              </div>)}
          </Translation>
        </Grid>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '50%', mr: 1 }}>
            <LinearProgress style={{height: '8px', borderRadius:'4px'}} variant="determinate" value={bulkVehicleImportProgress.progress}/>
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2">{`${Math.round(bulkVehicleImportProgress.progress)}%`}</Typography>
          </Box>
        </Box>
      </div>
    </Container>
  );
};

const getDynamicConfigurationAsNumber = (name: string, environment: EnvironmentState, defaultValue: number): number => {
  const stringValue = environment.dynamicConfigurations?.find(
    (item) => item.name === name
  )?.value;

  return stringValue === undefined ? defaultValue : parseInt(stringValue, 10);
}

interface BulkVehicleImportViewProps extends StateProps, DispatchProps {}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: RootReducer) => {
  const { environment } = state;
  const { calculatedWhitelistDelta, bulkVehicleImportProgress } = state.parkingProducts
  const bulkVehicleImportPollingIntervalInSeconds = getDynamicConfigurationAsNumber("BulkVehicleImportPollingIntervalInSeconds", environment, 5);

  return {
    calculatedWhitelistDelta,
    bulkVehicleImportProgress,
    bulkVehicleImportPollingIntervalInSeconds
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getBulkVehicleImportProgress: (
    seasonTicketOwnerCrmId: string,
    fileReference: string,
    pollBulkVehicleImportProgress: () => void
  ) =>
    dispatch(
      parkingProductsActions.getBulkVehicleImportProgress(
        seasonTicketOwnerCrmId,
        fileReference,
        pollBulkVehicleImportProgress
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkVehicleProgressView);
