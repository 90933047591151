export type DelegeesWithPagination = {
  totalRecords: number;
  pages: number;
  delegees: DelegeeWithProductsModel[];
};

export type DelegeeWithProductsModel = {
  registrationId: string;
  name: string;
  emailAddress: string;
  invitationAccepted: boolean;
  invitationDate: string;
  products: DelegatedParkingProductModel[];
};

export type DelegatedParkingRightsWithPagination = {
  totalRecords: number;
  pages: number;
  delegatedParkingRights: DelegatedParkingRight[];
}

export type AssignedParkingRightsWithPagination = {
  totalRecords: number;
  pages: number;
  assignedParkingRights: AssignedParkingRight[];
};

export type VehicleAssignmentsMyFleetWithPagination = {
  totalRecords: number;
  pages: number;
  vehicleAssignments: VehicleAssignment[];
};

export type VehicleAssignmentModel = {
  assignedProducts: AssignedProduct[];
  customerId: string;
  vehicle: {
    countryCode: string,
    description: string,
    value: string
  };
};

export type DelegatedParkingRight = {
  parkingRight : DelegatedParkingProductModel,
  delegee : DelegeeModel,
}

export type Vehicle = {
  countryCode: string;
  identifier: string;
  description?: string;
};

export type AssignedParkingRight = {
  vehicleAssignmentId: string;
  parkingRight: {
    id: string;
    pmc: number;
    name: string;
    location: string;
  };
  assigned: Date;
  vehicle: Vehicle;
  startedAt: Date | null;
  flexBudgetRemainingTime?: string;
  flexBudgetUnitType?: FlexBudgetUnitType;
};

export type VehicleAssignmentProduct = {
  parkingRightId: string;
  pmcId: number;
  pmcName: string;
  locationName: string;
}

export type VehicleAssignment = {
  vehicleAssignmentId: string;
  vehicle: Vehicle;
  products: VehicleAssignmentProduct[];
  assignedAt: Date;
}

export type DelegatedParkingProductModel = {
  parkingRightId: string,
  id: number;
  pmcId: number;
  name: string;
  location: string;
  created: Date;
  status: number;
  flexBudgetRemainingTime: string | undefined;
  flexBudgetUnitType: FlexBudgetUnitType | undefined;
};

export type DelegeeModel = {
  registrationId: string;
  name: string;
  emailAddress: string;
  invitationAccepted: boolean;
}

export type AssignedProduct = {
  assignedOn: Date;
  orderLineId: number;
  parkingRightId: string;
  pmcId: number;
}

export enum FlexBudgetUnitType {
  Hours = 1,
  Quarters = 2,
  Minutes = 3
}