import { request, success, failure } from "../helpers/action.helper";
import { parkingProductsTypes } from "../action-types/parkingProducts.types";
import parkingProductsService, { convertFiltersToGetVehicleAssignmentsQuery } from "../../services/parkingProducts.service";
import { Dispatch } from "react";
import { ParkingRightsRequestModel } from "../../models/ParkingRightsRequestModel";
import { RevokeParkingRightsModel } from "../../models/RevokeParkingRightsModel";
import { toastTriggers } from "../../ui/CustomToast/toastTriggers";
import { isUndefined, isNull, isEmpty } from "underscore";
import { AxiosError, AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { RootReducer } from "../reducers";
import { ParkingProductModel } from "../../models/ParkingProductModel";
import {
  AssignedParkingRight,
  DelegatedParkingRight,
  DelegatedParkingRightsWithPagination,
  DelegeesWithPagination,
  DelegeeWithProductsModel,
  VehicleAssignment,
  VehicleAssignmentModel,
} from "../../models/delegations/DelegatedParkingProductsModel";
import { LocatedParkingProduct } from "../../models/LocatedParkingProduct";
import { loaderActions } from "./loader.actions";
import { ErrorDetailsModel } from "../../models/ErrorDetailsModel";
import { DeleteDelegationsRequestModel } from "../../models/delegations/DeleteDelegationsRequestModel";
import { WithFiltersAndPaging } from "../../models/filters/WithFiltersAndPaging";
import { ForFleetManager } from "../../models/ForFleetManager";
import {
  checkForParkingRightsAlreadyLinkedToALicensePlate,
  checkForSeasonTicketCrmIdNotAvailableError,
} from "../../utils";
import { ValidateDelegeesRequestModel } from "../../models/bulkImport/ValidateDelegeesRequestModel";
import { ValidateDelegeesResponseModel } from "../../models/bulkImport/ValidateDelegeesResponseModel";
import { StartBulkInvitationsRequestModel } from "../../models/bulkImport/StartBulkInvitationsRequestModel";
import { GetBulkImportProgressResponseModel } from "../../models/bulkImport/GetBulkImportProgressResponseModel";
import { GetBulkImportStatusResponseModel } from "../../models/bulkImport/GetBulkImportStatusResponseModel";
import { AssignVehicleRequestModel } from "../../models/vehicle-assignments/AssignVehicleRequestModel";
import { UpdateVehicleRequestModel } from "../../models/vehicle-assignments/UpdateVehicleRequestModel";
import { AssignedVehicleSuccessfulMutation } from "../../models/vehicle-assignments/AssignedVehicleSuccessfulMutation";
import { GetVehicleAssignmentsQuery } from "../../api/parkingProducts.api";
import { RevokeVehicleAssignmentsModel } from "../../models/vehicle-assignments/RevokeVehicleAssignmentsModel";
import { VehicleAssignmentProduct } from "../../models/vehicle-assignments/VehicleAssignmentProduct";
import { DeleteVehicleAssignmentsModel } from "../../models/vehicle-assignments/DeleteVehicleAssignmentsModel";
import { CalculatedWhitelistDeltaResponse, CalculateWhitelistDeltaRequest } from "../../models/vehicle-assignments/CalculateWhitelistDelta";
import { GetBulkVehicleImportProgressResponseModel } from "../../models/vehicle-assignments/GetBulkVehicleImportProgressResponseModel";
import { StartBulkVehicleAssignmentRequest } from "../../models/vehicle-assignments/StartBulkVehicleAssignmentRequest";
import { GetBulkVehicleAssignmentsStatusResponseModel } from "../../models/vehicle-assignments/GetBulkVehicleAssignmentsStatusResponseModel";
import { ValidatedVehicleBulkUploadResponse, ValidateVehicleBulkUploadRequest } from "../../models/vehicle-assignments/ValidateVehicleBulkUpload";

type StrictParkingProductsAction =
  | { type: typeof parkingProductsTypes.SET_MY_FLEET_VEHICLES_TOTAL_RECORDS, payload: { totalRecords: number } }

type AllowSuffix<T extends string> = T | `${T}_REQUEST` | `${T}_SUCCESS` | `${T}_FAILURE`;

type TheRestOfTypes = AllowSuffix<Exclude<(typeof parkingProductsTypes)[keyof typeof parkingProductsTypes], StrictParkingProductsAction["type"]>>;

export type ParkingProductsActionType = {
  type: TheRestOfTypes;
  parkingProductsPayload?: ParkingProductModel[];
  locatedParkingProductsPayload?: LocatedParkingProduct[];
  delegatedParkingProductsPayload?: {
    pageNumber: number;
    totalPages?: number;
    totalRecords?: number;
    payload?: DelegatedParkingRight[];
  };
  assignedVehiclesProductsPayload?: {
    query?: GetVehicleAssignmentsQuery;
    pageNumber: number;
    totalPages?: number;
    totalRecords?: number;
    payload?: AssignedParkingRight[];
  };
  delegeesPayload?: {
    pageNumber: number;
    totalPages?: number;
    totalRecords?: number;
    payload?: DelegeeWithProductsModel[];
  };
  delegeeDetailsPayload?: DelegeeWithProductsModel;
  selectedVehicleAssignment?: VehicleAssignmentModel,
  validateDelegeesPayload?: {
    fileReference: string
  },
  bulkImportProgress?: {
    progress: number;
    hasErrors: boolean;
    numberOfErrors: number;
  },
  vehicleAssigmentsMyFleetPayload?: {
    query?: GetVehicleAssignmentsQuery;
    pageNumber: number;
    totalPages?: number;
    totalRecords?: number;
    payload?: VehicleAssignment[];
  },
  revokeProductsModel?: RevokeVehicleAssignmentsModel,
  assignedVehicleMutation?: AssignedVehicleSuccessfulMutation;
  isBulkImportCompleted?: boolean;
  error?: ErrorDetailsModel;
  calculatedWhitelistDelta?: {
    amountOfVehiclesToUpdate: number;
    amountOfVehiclesToCreate: number;
    amountOfVehiclesToDelete: number;
    amountOfVehiclesWithNoChanges: number;
    hasChanges: boolean;
    fileReference: string;
  },
  bulkVehicleImportProgress?: {
    progress: number;
  },
  isBulkVehicleAssignmentsCompleted?: boolean;
  validatedVehicleBulkUpload?: {
    amountOfVehiclesToCreate: number;
    amountOfExceedingVehicles: number;
    fileReference: string;
  };
};

export type ParkingProductsAction = StrictParkingProductsAction | ParkingProductsActionType;

const getParkingProducts = (
  seasonTicketOwnerCrmId: string,
  location: string
) => {
  return (dispatch: Dispatch<ParkingProductsAction>) => {
    dispatch({
      type: request(parkingProductsTypes.GET_PARKING_PRODUCTS),
    });

    parkingProductsService
      .getParkingProducts(seasonTicketOwnerCrmId, location)
      .then((response: AxiosResponse<ParkingProductModel[]>) => {
        dispatch({
          type: success(parkingProductsTypes.GET_PARKING_PRODUCTS),
          parkingProductsPayload: response.data,
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.GET_PARKING_PRODUCTS),
          error: error.response?.data as ErrorDetailsModel | undefined,
        });
      });
  };
};

const getUnlimitedParkingProducts = (
  seasonTicketOwnerCrmId: string,
  location: string
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.GET_PARKING_PRODUCTS),
    });

    parkingProductsService
      .getParkingProducts(seasonTicketOwnerCrmId, location)
      .then((response: AxiosResponse<ParkingProductModel[]>) => {
        dispatch({
          type: success(parkingProductsTypes.GET_PARKING_PRODUCTS),
          parkingProductsPayload: response.data.filter(p => p.hasUnlimitedEntryRights),
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.GET_PARKING_PRODUCTS),
          error: error.response?.data as ErrorDetailsModel | undefined,
        });
      });
  };
};

const getLocatedParkingProducts = (
  seasonTicketOwnerCrmId: string,
  language: string
) => {
  return (dispatch: Dispatch<any>, getState: () => RootReducer) => {
    dispatch({
      type: request(parkingProductsTypes.GET_LOCATED_PARKING_PRODUCTS),
    });

    const { locatedParkingProducts } = getState().parkingProducts;

    if (isEmpty(locatedParkingProducts.data)) {
      dispatch(loaderActions.loadTheLoader());
    }

    parkingProductsService
      .getLocatedParkingProducts(seasonTicketOwnerCrmId, language)
      .then((response: AxiosResponse<LocatedParkingProduct[]>) => {
        dispatch({
          type: success(parkingProductsTypes.GET_LOCATED_PARKING_PRODUCTS),
          locatedParkingProductsPayload: response.data,
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.GET_LOCATED_PARKING_PRODUCTS),
          error: error.response?.data,
        });
      })
      .finally(() => dispatch(loaderActions.killTheLoader()));
  };
};

const delegateParkingRights = (
  model: ParkingRightsRequestModel,
  callback?: () => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.DELEGATE_PARKING_RIGHTS),
    });

    parkingProductsService
      .delegateParkingRights(model)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.DELEGATE_PARKING_RIGHTS),
        });

        if (!isUndefined(callback)) {
          callback();
        }
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        checkForCrmIdOrLinkedAccessDevice(
          error.response?.data as ErrorDetailsModel | null
        );

        dispatch({
          type: failure(parkingProductsTypes.DELEGATE_PARKING_RIGHTS),
          error: error.response?.data as ErrorDetailsModel,
        });
      });
  };
};

const updateDelegation = (
  model: ParkingRightsRequestModel,
  callback?: () => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.UPDATE_DELEGATION),
    });

    parkingProductsService
      .updateDelegation(model)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.UPDATE_DELEGATION),
        });

        if (!isUndefined(callback)) {
          callback();
        }

        toastTriggers.success({
          content: {
            key: "parkingRights.updateDelegation.success",
          },
        });
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.UPDATE_DELEGATION),
          error: error.response?.data as ErrorDetailsModel,
        });
      });
  };
};

const deleteDelegee = (registrationId: string, callback?: () => void) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.DELETE_DELEGEE),
    });

    parkingProductsService
      .deleteDelegee(registrationId)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.DELETE_DELEGEE),
        });

        if (!isUndefined(callback)) {
          callback();
        }

        toastTriggers.success({
          content: {
            key: "parkingRights.deleteDelegee.success",
          },
        });
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.DELETE_DELEGEE),
          error: error.response?.data as ErrorDetailsModel,
        });

        toastTriggers.error({
          content: {
            key: "parkingRights.deleteDelegee.error",
          },
        });
      });
  };
};

const getDelegatedParkingProducts = (
  req: WithFiltersAndPaging<ForFleetManager>
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.GET_DELEGATED_PARKING_RIGHTS),
      delegatedParkingProductsPayload: {
        pageNumber: req.pagination.pageNumber,
      },
    });

    parkingProductsService
      .getDelegatedParkingProducts(req)
      .then((response: AxiosResponse<DelegatedParkingRightsWithPagination>) =>
        dispatch({
          type: success(parkingProductsTypes.GET_DELEGATED_PARKING_RIGHTS),
          delegatedParkingProductsPayload: {
            pageNumber: req.pagination.pageNumber,
            totalPages: response.data.pages,
            totalRecords: response.data.totalRecords,
            payload: response.data.delegatedParkingRights,
          },
        })
      )
      .catch((error: AxiosError) =>
        dispatch({
          type: failure(parkingProductsTypes.GET_DELEGATED_PARKING_RIGHTS),
          error: error.response?.data as ErrorDetailsModel | undefined,
        })
      );
  };
};

const getVehicles = (
  req: WithFiltersAndPaging<ForFleetManager>,
  vehicleToExclude?: VehicleAssignmentProduct[],
  excludeByVehicleAssignmentId? : boolean
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    const query = convertFiltersToGetVehicleAssignmentsQuery(req);
    // We overfetch the page because possible we have to remove the revoked/deleted rows
    if (vehicleToExclude) {
      query.pageSize += vehicleToExclude.length;
    }
    dispatch({
      type: request(parkingProductsTypes.GET_ASSIGNED_VEHICLES),
      assignedVehiclesProductsPayload: {
        pageNumber: req.pagination.pageNumber,
        query
      },
    });

    parkingProductsService
      .getVehicles(query)
      .then((response) => {
        // vehicles that are revokes/deleted can still be in projection, thus we exclude them directly after revoke/delete is called
        if (vehicleToExclude) {
          const rowsToExclude = response.data.assignedParkingRights.filter(p =>
              vehicleToExclude.some(v => excludeByVehicleAssignmentId ?
                  v.vehicleAssignmentId === p.vehicleAssignmentId :
                  v.vehicleAssignmentId === p.vehicleAssignmentId && v.pmcId === p.parkingRight.pmc));
          response.data.totalRecords -= rowsToExclude.length;
          response.data.assignedParkingRights = response.data.assignedParkingRights.filter(x => !rowsToExclude.includes(x));
        }
        dispatch({
          type: success(parkingProductsTypes.GET_ASSIGNED_VEHICLES),
          assignedVehiclesProductsPayload: {
            pageNumber: req.pagination.pageNumber,
            totalPages: response.data.pages,
            totalRecords: response.data.totalRecords,
            payload: response.data.assignedParkingRights,
          },
        })
      }

      )
      .catch((error: AxiosError) =>
        dispatch({
          type: failure(parkingProductsTypes.GET_ASSIGNED_VEHICLES),
          error: error.response?.data as ErrorDetailsModel | undefined,
        })
      );
  };
};


const clearDelegeeDetails = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: parkingProductsTypes.CLEAR_DELEGEE_DETAILS });
  };
};

const resetBulkImportProgress = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch({ type: parkingProductsTypes.RESET_BULK_UPLOAD_PROGRESS });
  };
};

const disposeError = () => {
  return (dispatch: Dispatch<AnyAction>, getState: () => RootReducer) => {
    const { error } = getState().parkingProducts;

    if (!isNull(error)) {
      dispatch({
        type: parkingProductsTypes.DISPOSE_ERROR,
      });
    }
  };
};

const getDelegeeDetails = (
  registrationId: string,
  language: string,
  callback?: () => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.GET_DELEGEE_DETAILS),
    });

    parkingProductsService
      .getDelegeeDetails(registrationId, language)
      .then((response: AxiosResponse<DelegeeWithProductsModel>) => {
        dispatch({
          type: success(parkingProductsTypes.GET_DELEGEE_DETAILS),
          delegeeDetailsPayload: response.data,
        });

        if (!isUndefined(callback)) {
          callback();
        }
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.GET_PARKING_PRODUCTS),
          error: error.response?.data as ErrorDetailsModel | undefined,
        });
      });
  };
};

const getBulkImportProgress = (
  seasonTicketOwnerCrmId: string,
  fileReference: string,
  pollBulkImportProgress?: () => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    parkingProductsService
      .getBulkImportProgress(seasonTicketOwnerCrmId, fileReference)
      .then((response: AxiosResponse<GetBulkImportProgressResponseModel>) => {
        dispatch({
          type: success(parkingProductsTypes.GET_BULK_IMPORT_PROGRESS),
          bulkImportProgress: {
            progress: response.data.progress,
            hasErrors: response.data.hasErrors,
            numberOfErrors: response.data.numberOfErrors
          }
        });
        if (!isUndefined(pollBulkImportProgress) && response.data.progress !== 100) {
          pollBulkImportProgress();
        }
      })
      .catch((error: AxiosError) => {
        // For testing purposes (until endpoint is implemented)
        // dispatch({
        //   type: success(parkingProductsTypes.GET_BULK_IMPORT_PROGRESS),
        //   bulkImportProgress: progress += 10,
        // });
        dispatch({
          type: failure(parkingProductsTypes.GET_BULK_IMPORT_PROGRESS),
          error: error.response?.data as ErrorDetailsModel | undefined,
        });
      });
  };
};

const getBulkVehicleImportProgress = (
  seasonTicketOwnerCrmId: string,
  fileReference: string,
  pollBulkImportProgress?: () => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    parkingProductsService
      .getBulkVehicleImportProgress(seasonTicketOwnerCrmId, fileReference)
      .then((response: AxiosResponse<GetBulkVehicleImportProgressResponseModel>) => {
        dispatch({
          type: success(parkingProductsTypes.GET_BULK_VEHICLE_IMPORT_PROGRESS),
          bulkVehicleImportProgress: {
            progress: response.data.progress
          }
        });
        if (!isUndefined(pollBulkImportProgress) && response.data.progress !== 100) {
          pollBulkImportProgress();
        }
      })
      .catch((error: AxiosError) => {
        // For testing purposes (until endpoint is implemented)
        // dispatch({
        //   type: success(parkingProductsTypes.GET_BULK_IMPORT_PROGRESS),
        //   bulkImportProgress: progress += 10,
        // });
        dispatch({
          type: failure(parkingProductsTypes.GET_BULK_VEHICLE_IMPORT_PROGRESS),
          error: error.response?.data as ErrorDetailsModel | undefined,
        });
      });
  };
};

const getBulkImportStatus = (seasonTicketOwnerCrmId: string) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.GET_BULK_IMPORT_STATUS)
    });

    parkingProductsService.getBulkImportStatus(seasonTicketOwnerCrmId).then((response: AxiosResponse<GetBulkImportStatusResponseModel>) => {
      dispatch({
        type: success(parkingProductsTypes.GET_BULK_IMPORT_STATUS),
        isBulkImportCompleted: response.data.processCompleted
      });
    }).catch((error: AxiosError) => {
      dispatch({
        type: failure(parkingProductsTypes.GET_BULK_IMPORT_STATUS),
        error: error.response?.data as ErrorDetailsModel | undefined,
      });
    });
  }
}

const resendDelegeeInvite = (
  registrationId: string,
  seasonTicketOwnerCrmId: string
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.RESEND_DELEGEE_INVITE),
    });

    parkingProductsService
      .resendDelegeeInvite(registrationId, seasonTicketOwnerCrmId)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.RESEND_DELEGEE_INVITE),
        });

        toastTriggers.success({
          content: {
            key: "parkingRights.resendInvite.success",
          },
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.RESEND_DELEGEE_INVITE),
          error: error.response?.data as ErrorDetailsModel | undefined,
        });

        toastTriggers.error({
          content: {
            key: "parkingRights.resendInvite.error",
          },
        });
      });
  };
};

const getDelegees = (req: WithFiltersAndPaging<ForFleetManager>) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.GET_DELEGEES),
      delegeesPayload: {
        pageNumber: req.pagination.pageNumber,
      },
    });

    parkingProductsService
      .getDelegees(req)
      .then((response: AxiosResponse<DelegeesWithPagination>) => {
        dispatch({
          type: success(parkingProductsTypes.GET_DELEGEES),
          delegeesPayload: {
            pageNumber: req.pagination.pageNumber,
            totalPages: response.data.pages,
            totalRecords: response.data.totalRecords,
            payload: response.data.delegees,
          },
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.GET_DELEGEES),
          error: error.response?.data as ErrorDetailsModel | undefined,
        });
      });
  };
};

const revokeParkingRights = (
  model: RevokeParkingRightsModel,
  callback?: () => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.REVOKE_PARKINGRIGHTS),
    });

    parkingProductsService
      .revokeParkingRights(model)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.REVOKE_PARKINGRIGHTS),
        });

        if (!isUndefined(callback)) {
          callback();
        }

        toastTriggers.success({
          content: {
            key: "parkingRights.revokeParkingRights.success",
            options: { count: model.parkingRightIds.length },
          },
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.REVOKE_PARKINGRIGHTS),
          error: error.response?.data as ErrorDetailsModel,
        });

        toastTriggers.error({
          content: {
            key: "parkingRights.revokeParkingRights.error",
          },
        });
      });
  };
};

const deleteDelegations = (
  model: DeleteDelegationsRequestModel,
  callback?: () => void
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: request(parkingProductsTypes.DELETE_DELEGEES),
    });

    dispatch(loaderActions.loadTheLoader());

    parkingProductsService
      .deleteDelegations(model)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.DELETE_DELEGEES),
        });

        if (!isUndefined(callback)) {
          callback();
        }

        toastTriggers.success({
          content: {
            key: "parkingRights.deleteDelegee.success_plural",
            options: { count: model.registrationIds.length },
          },
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.DELETE_DELEGEES),
          error: error.response?.data as ErrorDetailsModel,
        });

        toastTriggers.error({
          content: {
            key: "parkingRights.deleteDelegee.error_plural",
          },
        });
      })
      .finally(() => dispatch(loaderActions.killTheLoader()));
  };
};

const validateDelegees = (
  seasonTicketOwnerCrmId: string,
  req: ValidateDelegeesRequestModel,
  onSuccess?: (response: ValidateDelegeesResponseModel) => void,
  onError?: (errorCode: string) => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.VALIDATE_DELEGEES),
    });

    parkingProductsService
      .validateDelegees(seasonTicketOwnerCrmId, req)
      .then(({ data }) => {
        dispatch({
          type: success(parkingProductsTypes.VALIDATE_DELEGEES),
          validateDelegeesPayload: {
            fileReference: data.fileReference
          }
        });
        if (!isUndefined(onSuccess)) {
          onSuccess(data);
        }
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.VALIDATE_DELEGEES),
        });

        const errorCode = getFirstErrorCode(error);

        if (!isUndefined(onError)) {
          onError(errorCode);
        }
      });
  };
};

const startBulkInvitations = (
  seasonTicketOwnerCrmId: string,
  fileReference: string,
  req: StartBulkInvitationsRequestModel,
  onSuccess?: () => void,
  onError?: (errorCode: string) => void
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: request(parkingProductsTypes.IMPORT_DELEGEES),
    });

    parkingProductsService
      .startBulkInvitations(seasonTicketOwnerCrmId, fileReference, req)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.IMPORT_DELEGEES),
        });
        if (!isUndefined(onSuccess)) {
          onSuccess();
        }
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.IMPORT_DELEGEES),
        });

        const errorCode = getFirstErrorCode(error);

        if (!isUndefined(onError)) {
          onError(errorCode);
        }
      });
  };
}

const startBulkVehicleAssignment = (
  seasonTicketOwnerCrmId: string,
  fileReference: string,
  startBulkVehicleAssignmentRequest: StartBulkVehicleAssignmentRequest,
  onSuccess?: () => void,
  onError?: (errorCode: string) => void
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: request(parkingProductsTypes.START_BULK_VEHICLE_ASSIGNMENT),
    });

    parkingProductsService
      .startBulkVehicleAssignment(seasonTicketOwnerCrmId, fileReference, startBulkVehicleAssignmentRequest)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.START_BULK_VEHICLE_ASSIGNMENT),
        });
        if (!isUndefined(onSuccess)) {
          onSuccess();
        }
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.START_BULK_VEHICLE_ASSIGNMENT),
        });

        const errorCode = getFirstErrorCode(error);

        if (!isUndefined(onError)) {
          onError(errorCode);
        }
      });
  };
}

const assignVehicle = (
  seasonTicketOwnerCrmId: string,
  req: AssignVehicleRequestModel,
  onSuccess?: () => void,
  onError?: (errorCode: string) => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.ASSIGN_VEHICLE),
    });

    parkingProductsService
      .assignVehicle(seasonTicketOwnerCrmId, req)
      .then((response) => {
        dispatch({
          type: success(parkingProductsTypes.ASSIGN_VEHICLE),
          assignedVehicleMutation: {
            type: 'assign',
            request: req,
            pmcToParkingRightStartDates: response.data.pmcToParkingRight
          }
        });
        if (!isUndefined(onSuccess)) {
          onSuccess();
        }
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.ASSIGN_VEHICLE),
          error: error.response?.data as ErrorDetailsModel,
        });

        const errorCode = getFirstErrorCode(error);

        if (!isUndefined(onError)) {
          onError(errorCode);
        }
      });
  };
}
const updateVehicleAssignment = (
  seasonTicketOwnerCrmId: string,
  aggregateId: string,
  req: UpdateVehicleRequestModel,
  onSuccess?: () => void) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.UPDATE_VEHICLE_ASSIGNMENT),
    });

    parkingProductsService
      .updateVehicleAssignment(seasonTicketOwnerCrmId, aggregateId, req)
      .then((response) => {
        dispatch({
          type: success(parkingProductsTypes.UPDATE_VEHICLE_ASSIGNMENT),
          assignedVehicleMutation: {
            type: 'update',
            aggregateId,
            request: req,
            pmcToParkingRightStartDates: response.data.pmcToParkingRight
          }
        });
        if (!isUndefined(onSuccess)) {
          onSuccess();
        }
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.UPDATE_VEHICLE_ASSIGNMENT),
          error: error.response?.data as ErrorDetailsModel,
        });
      });
  };
}

const getVehicleAssignment = (
  aggregateId: string, onSuccess?: (res: VehicleAssignmentModel) => void) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.GET_ASSIGNED_VEHICLE),
    });

    parkingProductsService.getVehicleAssignment(aggregateId)
      .then((response) => {
        dispatch({
          type: success(parkingProductsTypes.GET_ASSIGNED_VEHICLE),
          selectedVehicleAssignment: response.data
        });
        if (!isUndefined(onSuccess)) {
          onSuccess(response.data);
        }
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.GET_ASSIGNED_VEHICLE),
          error: error.response?.data as ErrorDetailsModel,
        });
      });
  };
}

const getVehicleAssignmentsMyFleet = (req: WithFiltersAndPaging<ForFleetManager>, excludedIds: string[] = []) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    const query = convertFiltersToGetVehicleAssignmentsQuery(req);
    const originalPageSize = req.pagination.pageSize;
    // overfetch to fill the page in case deleted items are still in the projection
    query.pageSize += excludedIds.length;
    dispatch({
      type: request(parkingProductsTypes.GET_VEHICLE_ASSIGNMENTS_MY_FLEET),
      vehicleAssigmentsMyFleetPayload: {
        pageNumber: req.pagination.pageNumber,
        query
      }
    });

    parkingProductsService.getVehicleAssignmentsMyFleet(query)
      .then((response) => {
        const toRemove = response.data.vehicleAssignments.filter(x => excludedIds.includes(x.vehicleAssignmentId));
        const vehicle = response.data.vehicleAssignments.filter(x => !toRemove.includes(x)).slice(0, originalPageSize);

        dispatch({
          type: success(parkingProductsTypes.GET_VEHICLE_ASSIGNMENTS_MY_FLEET),
          vehicleAssigmentsMyFleetPayload: {
            pageNumber: req.pagination.pageNumber,
            totalPages: response.data.pages,
            totalRecords: response.data.totalRecords - toRemove.length,
            payload: vehicle
          }
        });
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.GET_VEHICLE_ASSIGNMENTS_MY_FLEET),
          error: error.response?.data as ErrorDetailsModel,
        });
      });
  };
}

const prefetchMyFleetVehicleTotalCount = (crmId: string) => {
  return (dispatch: Dispatch<ParkingProductsAction>) => {
    parkingProductsService.getVehicleAssignmentsMyFleet({
        page: 1,
        pageSize: 1,
        language: "en",
        seasonTicketOwnerCrmId: crmId,
    }).then((response) => {
        dispatch({
            type: parkingProductsTypes.SET_MY_FLEET_VEHICLES_TOTAL_RECORDS,
            payload: {
              totalRecords: response.data.totalRecords
            }
        });
    });
  };
}

const getFirstErrorCode = (error: AxiosError<ErrorDetailsModel>) =>{
    let errorCode = error.message;
    const errors = error.response?.data.errors;
    const fields = error.response?.data.fields;

    if (errors !== undefined && errors.length > 0 &&
        fields !== undefined && fields.length > 0) {
        errorCode = Object.values(errors)[0][fields[0]];
    }

    return errorCode;
}

const revokeVehicleAssignmentProducts = (
  model: RevokeVehicleAssignmentsModel,
  callback?: () => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.REVOKE_VEHICLE_ASSIGNMENTS),
      revokeProductsModel: model
    });

    parkingProductsService
      .revokeVehicleAssignments(model)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.REVOKE_VEHICLE_ASSIGNMENTS),
          revokeProductsModel: model
        });

        if (!isUndefined(callback)) {
          callback();
        }

        toastTriggers.success({
          content: {
            key: "parkingRights.revokeVehicleAssignment.success",
            options: { count: model.vehicleAssignmentProducts.length },
          },
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.REVOKE_VEHICLE_ASSIGNMENTS),
          error: error.response?.data as ErrorDetailsModel,
        });

        toastTriggers.error({
          content: {
            key: "parkingRights.revokeVehicleAssignment.error",
          },
        });
      });
  };
};

const deleteVehicleAssignments = (
  model: DeleteVehicleAssignmentsModel,
  callback?: () => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.DELETE_VEHICLE_ASSIGNMENTS),
    });

    parkingProductsService
      .deleteVehicleAssignments(model)
      .then(() => {
        dispatch({
          type: success(parkingProductsTypes.DELETE_VEHICLE_ASSIGNMENTS),
        });

        if (!isUndefined(callback)) {
          callback();
        }

        toastTriggers.success({
          content: {
            key: "parkingRights.deleteVehicleAssignment.success",
            options: { count: model.vehicleAssignmentIds.length },
          },
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: failure(parkingProductsTypes.DELETE_VEHICLE_ASSIGNMENTS),
          error: error.response?.data as ErrorDetailsModel,
        });

        toastTriggers.error({
          content: {
            key: "parkingRights.deleteVehicleAssignment.error",
          },
        });
      });
  };
};

const calculateWhitelistDelta = (
  req: CalculateWhitelistDeltaRequest,
  onSuccess?: (response: CalculatedWhitelistDeltaResponse) => void,
  onError?: (errorCode: string) => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.CALCULATE_WHITELIST_DELTA),
    });
    parkingProductsService
      .calculateWhitelistDelta(req)
      .then(({ data }) => {
        dispatch({
          type: success(parkingProductsTypes.CALCULATE_WHITELIST_DELTA),
          calculatedWhitelistDelta: {
            amountOfVehiclesToCreate: data.amountOfVehiclesToCreate,
            amountOfVehiclesToDelete: data.amountOfVehiclesToDelete,
            amountOfVehiclesToUpdate: data.amountOfVehiclesToUpdate,
            amountOfVehiclesWithNoChanges: data.amountOfVehiclesWithNoChanges,
            hasChanges: data.hasChanges,
            fileReference: data.fileReference
          }
        });
        if (!isUndefined(onSuccess)) {
          onSuccess(data);
        }
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.CALCULATE_WHITELIST_DELTA),
          error: error.response?.data as ErrorDetailsModel,
        });

        const errorCode = getFirstErrorCode(error);

        if (!isUndefined(onError)) {
          onError(errorCode);
        }
      });
  };
};

const getBulkVehicleAssignmentsStatus = (seasonTicketOwnerCrmId: string) => {
    return (dispatch: Dispatch<ParkingProductsActionType>) => {
        dispatch({
            type: request(parkingProductsTypes.GET_BULK_VEHICLE_ASSIGNMENTS_STATUS)
        });

        parkingProductsService.getBulkVehicleAsignmentsStatus(seasonTicketOwnerCrmId).then((response: AxiosResponse<GetBulkVehicleAssignmentsStatusResponseModel>) => {
            dispatch({
                type: success(parkingProductsTypes.GET_BULK_VEHICLE_ASSIGNMENTS_STATUS),
                isBulkVehicleAssignmentsCompleted: response.data.processCompleted
            });
        }).catch((error: AxiosError) => {
            dispatch({
                type: failure(parkingProductsTypes.GET_BULK_VEHICLE_ASSIGNMENTS_STATUS),
                error: error.response?.data as ErrorDetailsModel | undefined,
            });
        });
    }
}

const resetBulkVehicleImportProgress = () => {
  return { type: parkingProductsTypes.RESET_BULK_VEHICLE_IMPORT_PROGRESS };
}

const validateVehicleBulkUpload = (
  req: ValidateVehicleBulkUploadRequest,
  onSuccess?: (response: ValidatedVehicleBulkUploadResponse) => void,
  onError?: (errorCode: string) => void
) => {
  return (dispatch: Dispatch<ParkingProductsActionType>) => {
    dispatch({
      type: request(parkingProductsTypes.VALIDATE_VEHICLE_BULK_UPLOAD),
    });
    parkingProductsService
      .validateVehicleBulkUpload(req)
      .then(({ data }) => {
        dispatch({
          type: success(parkingProductsTypes.VALIDATE_VEHICLE_BULK_UPLOAD),
          validatedVehicleBulkUpload: {
            amountOfVehiclesToCreate: data.amountOfVehiclesToCreate,
            amountOfExceedingVehicles: data.amountOfExceedingVehicles,
            fileReference: data.fileReference
          }
        });
        if (!isUndefined(onSuccess)) {
          onSuccess(data);
        }
      })
      .catch((error: AxiosError<ErrorDetailsModel>) => {
        dispatch({
          type: failure(parkingProductsTypes.VALIDATE_VEHICLE_BULK_UPLOAD),
          error: error.response?.data as ErrorDetailsModel,
        });

        const errorCode = getFirstErrorCode(error);

        if (!isUndefined(onError)) {
          onError(errorCode);
        }
      });
  };
};

export default {
  getParkingProducts,
  getUnlimitedParkingProducts,
  getLocatedParkingProducts,
  delegateParkingRights,
  updateDelegation,
  getDelegatedParkingProducts,
  getVehicles,
  disposeError,
  getDelegeeDetails,
  clearDelegeeDetails,
  deleteDelegee,
  resendDelegeeInvite,
  getDelegees,
  revokeParkingRights,
  deleteDelegations,
  validateDelegees,
  startBulkInvitations,
  getBulkImportProgress,
  getBulkImportStatus,
  getBulkVehicleAssignmentsStatus,
  resetBulkImportProgress,
  assignVehicle,
  updateVehicleAssignment,
  getVehicleAssignment,
  revokeVehicleAssignmentProducts,
  deleteVehicleAssignments,
  getVehicleAssignmentsMyFleet,
  calculateWhitelistDelta,
  startBulkVehicleAssignment,
  getBulkVehicleImportProgress,
  resetBulkVehicleImportProgress,
  prefetchMyFleetVehicleTotalCount,
  validateVehicleBulkUpload
};

const checkForCrmIdOrLinkedAccessDevice = (
  error: ErrorDetailsModel | null
): void => {
  if (checkForSeasonTicketCrmIdNotAvailableError(error)) {
    toastTriggers.error({
      content: {
        key: "parkingRights.seasonTicketOwnerCrmId.not_available",
      },
    });
  } else if (checkForParkingRightsAlreadyLinkedToALicensePlate(error)) {
    toastTriggers.error({
      content: {
        key: "parkingRights.accessDevice.alreadyLinked",
      },
    });
  }
};
