import React, { useCallback, useMemo } from "react";
import { QParkChip } from "../../../../components/QParkChip";
import { RootState, useAppSelector } from "../../../../store";
import useMyProductsContext from "../../../../context/hooks/useMyProductsContext";
import { ISelectOption } from "../../../../components/DataTable/DataTableFilters/DataTableFilterTypes/SelectFilter";
import { MenuAction } from "../../../../components/DataTable/DataTableHeading/DataTableHeading";
import { useTranslation } from "react-i18next";

import PersonIcon from "@material-ui/icons/Person";
import VehicleIcon from "../../../../ui/Icons/Vehicle";
import useFleetManagerContext from "../../../../context/hooks/useFleetManagerContext";
import { isNil } from "lodash";
import { NumberPlateDeSupport } from "../../../../ui/NumberPlate";
import { convertIsoCountryCodeToNumberPlatePrefix } from "../../../../lib/countriesNumberPlates";
import { FlexBudgetUnitType } from "../../../../models/delegations/DelegatedParkingProductsModel";

export type ProductChipProps = Readonly<{
  name: string;
  location: string;
}>;

export function ProductChip(props: ProductChipProps) {
  return <QParkChip.Labeled label={`${props.name} - ${props.location}`} />;
}

export type NumberPlateProps = Readonly<{
  countryCode: string;
  identifier: string;
}>;

export function NumberPlate(props: NumberPlateProps) {
  const numberPlatePrefix = convertIsoCountryCodeToNumberPlatePrefix(props.countryCode);
  return (
    <NumberPlateDeSupport prefix={numberPlatePrefix} value={props.identifier} readOnly compact />
  );
}

export function selectUseSingleDirectVehicleAssignment(state: RootState) {
  return state.environment.featureFlags.find(f => f.name === "UseSingleDirectVehicleAssignment")?.value;
}

export function selectUseFlexBudgetColumn(state: RootState) {
  return state.environment.featureFlags.find(f => f.name === "UseFlexBudgetColumn")?.value;
}

export function selectUseBulkVehicleAssignments(state: RootState) {
  return state.environment.featureFlags.find(f => f.name === "BulkVehicleAssignments")?.value && state.parkingProducts.hasUnlimitedEntryRights;
}

export function selectMultipleVehicleAssignments(state: RootState) {
  return state.environment.featureFlags.find(f => f.name === "useMultipleVehicleAssignments")?.value;
}

export function useGetOccupiedParkingRightsCount() {
  const useSingleDirectVehicleAssignment = useAppSelector(selectUseSingleDirectVehicleAssignment);

  return useCallback((pr: {totalAssignedParkingRights?: number, totalDelegatedParkingRights?: number}) => {
    if (useSingleDirectVehicleAssignment) {
      return (pr.totalAssignedParkingRights ?? 0) + (pr.totalDelegatedParkingRights ?? 0);
    }

    return pr.totalDelegatedParkingRights ?? 0;
  }, [useSingleDirectVehicleAssignment])
}

export function useSharedProductsMenuAction(): {
  menu: MenuAction;
  onAddDelegee: () => void;
  onAssignVehicle: () => void;
} {
  const { t } = useTranslation();
  const useSingleDirectVehicleAssignment = useAppSelector(selectUseSingleDirectVehicleAssignment);

  const { 
    toggleAssignVehicle, 
    toggleAddDelegee, 
    setSelectedParkingRightIds, 
    toggleMultipleVehicles 
  } = useFleetManagerContext();

  const useBulkVehicleAssignments = useAppSelector(selectUseBulkVehicleAssignments);

  return useMemo(() => {
    const onInviteFleetMemberClicked = () => {
      setSelectedParkingRightIds([]);
      toggleAddDelegee();
    };
    const onAssignVehicleClicked = () => {
      setSelectedParkingRightIds([]);
      toggleAssignVehicle();
    };
    const onWhitelistVehiclesClicked = () => {
      setSelectedParkingRightIds([]);
      toggleMultipleVehicles();
    };

    const getMenuItems = () => {
      const menuItems = [
        {
          label: t("fleetManagerTable:assignVehicle"),
          action: onAssignVehicleClicked,
          icon: <VehicleIcon/>
        }
      ];

      if (!useBulkVehicleAssignments) {
        menuItems.unshift({
          label: t("fleetManagerTable:inviteFleetMember"),
          action: onInviteFleetMemberClicked,
          icon: <PersonIcon/>
        });
      } else {
        menuItems.push({
          label: t("fleetManagerTable:whitelistVehicles"),
          action: onWhitelistVehiclesClicked,
          icon: <VehicleIcon/>
        });
      }

      return menuItems;
    };

    return {
      menu: {
        useMenu: useSingleDirectVehicleAssignment,
        showMenu: useSingleDirectVehicleAssignment,
        menuItems: getMenuItems()
      },
      onAddDelegee: onInviteFleetMemberClicked,
      onAssignVehicle: onAssignVehicleClicked,
    };
  }, [t, toggleAssignVehicle, toggleAddDelegee, setSelectedParkingRightIds, toggleMultipleVehicles]);
}

export function useProductOptions(): ISelectOption[] {
  const currentLocation = useCurrentlySelectedParkingProduct();

  return useMemo(() => {
    if (isNil(currentLocation)) return [];
    return currentLocation.parkingProducts.map((product) => ({
      label: product.pmcName,
      value: product.pmcId ?? 0,
    }));
  }, [currentLocation]);
}

export function useCurrentlySelectedParkingProduct() {
  const {
    myProductsState: { selectedLocation },
  } = useMyProductsContext();
  const locatedProduct = useAppSelector((state) =>
    state.parkingProducts.locatedParkingProducts.data?.find?.(
      (loc) => loc.placeId === selectedLocation
    )
  );
  return locatedProduct;
}

export function convertToRemainingFlex(flexBudRemainingTime: string | undefined, flexBudUnitType: FlexBudgetUnitType | undefined): string {
  if (!flexBudRemainingTime || flexBudRemainingTime == '') {
    return 'N/A';
  }

  const match = flexBudRemainingTime.match(/(?:(\d+)\.)?(\d{2}):(\d{2}):(\d{2})(?:\.(\d+))?/);

  if(!match) {
    return '';
  }

  const [_, dayPart, hoursPart, minutesPart] = match;
  const days: number = dayPart ? Number(dayPart) : 0;
  const hours: number = Number(hoursPart);
  const minutes: number = Number(minutesPart);

  const totalMinutes = days * 24 * 60 + hours * 60 + minutes;
  const totalHours = Math.floor(totalMinutes / 60);

  if (flexBudUnitType === FlexBudgetUnitType.Hours) {
    return `${totalHours}h`;
  }

  if (flexBudUnitType === FlexBudgetUnitType.Minutes) {
    if (totalHours !== 0) {
      return `${totalHours}h ${minutes}min`;
    }

    return `${minutes}min`;
  }

  if (flexBudUnitType === FlexBudgetUnitType.Quarters) {
    const roundedMinutes = Math.floor(minutes / 15) * 15;
    if (totalHours != 0) {
      return `${totalHours}h ${roundedMinutes}min`;
    }

    return `${roundedMinutes}min`;
  }

  return '';
}