import React, { Dispatch, useEffect, useMemo, useRef } from "react";
import { Formik } from "formik";
import { InputLabel, Grid, Container, Button, ButtonGroup, Box } from "@material-ui/core";
import { Translation, useTranslation } from "react-i18next";
import { CloseOutlined } from "@material-ui/icons";
import { variables } from "../../theme/variables";
import { without, isUndefined } from "underscore";
import Heading from "../../ui/Heading/Heading";
import CustomButton from "../../ui/CustomButton/CustomButton";
import CustomInput from "../../ui/CustomInput/CustomInput";
import ParkingRightsGrid from "../DelegeeForm/ParkingRightsGrid/ParkingRightsGrid";
import Spacer from "../../ui/Spacer/Spacer";
import { RootReducer } from "../../store/reducers";
import parkingProductsActions from "../../store/actions/parkingProducts.actions";
import { connect } from "react-redux";
import useValidationSchema, { IVehicleFormValues } from "./hooks/useValidationSchema";
import useAppContext from "../../context/hooks/useAppContext";
import { checkHasNoCapacity, checkLicensePlateAlreadyLinked, checkNumberPlateAlreadyAssigned, format } from "../../utils";
import CustomError from "../../ui/CustomError/CustomError";
import useFleetManagerContext from "../../context/hooks/useFleetManagerContext";
import useImportVehiclesContext from "../../context/hooks/useImportVehiclesContext";
import { AssignmentType } from "../../context/store/reducers/fleetManagerContext.reducer";
import { NoPaddingBottomGrid } from "../DataTable/DataTableStyledComponents";
import { NumberPlateDeSupport } from "../../ui/NumberPlate";
import { CountriesNumberPlates, convertIsoCountryCodeToNumberPlatePrefix, convertNumberPlatePrefixToIsoCountryCode, moveCountryToTop, getCountryCodeByLanguage } from "../../lib/countriesNumberPlates";
import RequiredInputLabel from "../../ui/RequiredInputLabel/RequiredInputLabel";
import { MuiHelperText } from "../MuiHelperText";
import { AssignVehicleRequestModel } from "../../models/vehicle-assignments/AssignVehicleRequestModel";
import { UpdateVehicleRequestModel } from "../../models/vehicle-assignments/UpdateVehicleRequestModel";
import { VehicleAssignmentModel } from "../../models/delegations/DelegatedParkingProductsModel";
import { DeleteDelegeeIcon, DeleteText, DeleteWrapper } from "../DataTable/DataTableFilters/CommonStyledComponents";
import { parseAssignedParkingRowKey } from "../../helpers/vehicleAssignment.utils";
import numberPlateValidator from "../../validators/numberplate.validator";
import { ParkingProductModel } from "../../models/vehicle-assignments/ParkingProductModel";
import { useAppSelector } from "../../store";
import { selectUseBulkVehicleAssignments, selectMultipleVehicleAssignments } from "../../containers/FleetManager/MyProducts/ParkingRightsDataTable/common";

const { typography } = variables;
interface IFormProps extends StateProps, DispatchProps {
  onClose: () => void;
  onRefresh?: () => void;
}

enum Fields {
  Description = "description",
  NumberPlate = "numberPlate",
  NumberPlatePrefix = "numberPlatePrefix",
  Products = "products"
}

type ValidationResult = { status: 'valid' } | { status: 'error' | 'warning'; message: string }

const VehicleForm: React.FC<IFormProps> = (props) => {
  const {
    onClose,
    onRefresh,
    parkingProducts,
    getParkingProducts,
    assignVehicle,
    getVehicleAssignment,
    disposeError,
    updateVehicleAssignment,
  } = props;

  const { appState } = useAppContext();
  const {
    fleetManagerState,
    setAssignmentType,
    toggleDeleteVehicleAssignmentsWarning,
    setNumberPlateWarning,
    setNumberPlateError,
    setVehicleDragAndDropView
  } = useFleetManagerContext();

  const { setSelectedProducts } = useImportVehiclesContext();

  const isEditMode = fleetManagerState.isEditVehicleAssignmentMode;

  const { t } = useTranslation(["vehicleForm", "visitors", "globals", "errors"]);

  useEffect(() => {
    return () => disposeError();
  },  [disposeError]);

  const newVehicleAssignmentIdRef = useRef<string>();
  const useBulkVehicleAssignments = useAppSelector(selectUseBulkVehicleAssignments);
  const useMultipleVehicleAssignments = useAppSelector(selectMultipleVehicleAssignments);

  const getParkingProductsFromPmcIds = (pmcIds: number[]) : ParkingProductModel[] =>
    parkingProducts.locatedParkingProducts.data
      ?.flatMap(l => l.parkingProducts)
      ?.filter(p => pmcIds.includes(p.pmcId!))
      .map(p => ({ pmcId: p.pmcId!, seasonTicketId: p.id!, countryCode: p.countryCode! })) || [];

  const handleFormSubmit = (values: IVehicleFormValues) => {
    if (fleetManagerState.assignmentType === AssignmentType.Multiple) {
      setSelectedProducts(values.products!);
      goToUploadFile();
      return;
    }

    if (isEditMode) {
      const updateRequest : UpdateVehicleRequestModel = {
        numberPlate: {
          countryCode: convertNumberPlatePrefixToIsoCountryCode(values.numberPlatePrefix).toLowerCase(),
          value: values.numberPlate.toUpperCase(),
        },
        pmcIds: values.products || [],
        parkingProducts: getParkingProductsFromPmcIds(values.products || []),
        description: values.description,
        isUnlimited: useBulkVehicleAssignments! && parkingProducts.hasUnlimitedEntryRights
      };

      updateVehicleAssignment(
        appState.user.seasonTicketOwnerCrmId!,
        getSelectedVehicleAssignemntId(),
        updateRequest,
        () => {
          onClose();
          if (onRefresh) {
            onRefresh();
          }
        });
    } else {
      // lazily gen vehicleAssignmentId for next successful assign vehicle request
      if (!newVehicleAssignmentIdRef.current) {
        newVehicleAssignmentIdRef.current = crypto.randomUUID();
      }
      const assignRequest : AssignVehicleRequestModel = {
        numberPlate: {
          countryCode: convertNumberPlatePrefixToIsoCountryCode(values.numberPlatePrefix).toLowerCase(),
          value: values.numberPlate.toUpperCase(),
        },
        pmcIds: values.products || [],
        parkingProducts: getParkingProductsFromPmcIds(values.products || []),
        description: values.description,
        aggregateId: newVehicleAssignmentIdRef.current,
        isUnlimited: useBulkVehicleAssignments! && parkingProducts.hasUnlimitedEntryRights
      };
      assignVehicle(appState.user.seasonTicketOwnerCrmId!, assignRequest, () => {
        // reset the vehicleAssignmentId
        newVehicleAssignmentIdRef.current = undefined;
        onClose();
        if (onRefresh) {
          onRefresh();
        }
      });
    }
  };

  const getSelectedVehicleAssignemntId = (): string => {
    const { vehicleAssignmentId } = parseAssignedParkingRowKey(fleetManagerState.selectedVehicleAssignment!.rowKey);
    return vehicleAssignmentId;
  }

  const onDeleteVehicleAssignment = () => {
      toggleDeleteVehicleAssignmentsWarning(fleetManagerState.selectedVehicleAssignment);
  }

  const topCountriesNumberPlatesDVA = new CountriesNumberPlates().getTopCountriesNumberPlatesDVA();

  const supportedNumberPlates = useMemo(() => {
    const countriesNumberPlates = new CountriesNumberPlates();
    const countries = countriesNumberPlates.getCountriesNumberPlates();

    const countryCode = getCountryCodeByLanguage(appState.selectedLanguage);
    const topCountriesNumberPlatesWithSelectedCountryTop =  moveCountryToTop(countryCode, topCountriesNumberPlatesDVA);

    let dvaCountries = topCountriesNumberPlatesWithSelectedCountryTop.concat(countries);

    return dvaCountries;
  }, []);

  const { validationSchema, formik } = useValidationSchema(
    t,
    isEditMode,
    handleFormSubmit,
    supportedNumberPlates[0].numberPlateCode
  );

  const fetchParkingProducts = () => {
    getParkingProducts(appState.user.seasonTicketOwnerCrmId as string, appState.selectedLanguage);
  };

  const initForm = () => {
    fetchParkingProducts();
    setNumberPlateWarning(undefined);
    setNumberPlateError(undefined);

    if (isEditMode) {
      setFieldValues();
    }
  };

  const setFieldValues = () => {
    getVehicleAssignment(getSelectedVehicleAssignemntId(), (vehicleAssignment: VehicleAssignmentModel) => {
      formik.setFieldValue(Fields.NumberPlatePrefix, convertIsoCountryCodeToNumberPlatePrefix(vehicleAssignment.vehicle.countryCode));
      formik.setFieldValue(Fields.NumberPlate, vehicleAssignment.vehicle.value);
      formik.setFieldValue(Fields.Description, vehicleAssignment.vehicle.description);
      formik.setFieldValue(Fields.Products, vehicleAssignment.assignedProducts.map(p => p.pmcId));
    });
  }

  useEffect(() => {
    initForm();
  }, []);

  useEffect(() => {
    if (hasNoCapacity()) {
      fetchParkingProducts();
    }
  }, [parkingProducts.error?.errors]);

  const handleSelectAll = (parkingProducts: number[]) => {
    formik.setFieldValue(Fields.Products, parkingProducts);
  };

  const handleDeselectAll = () => {
    formik.setFieldValue(Fields.Products, []);
  };

  const handleProductChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    parkingProducts: number[]
  ) => {
    const { value, checked } = event.currentTarget;
    const productId = parseInt(value);

    let products = [...parkingProducts];
    if (!checked) {
      products = without(products, productId);
    } else {
      products.push(productId);
    }
    formik.setFieldValue(Fields.Products, products);
  };

  const onInputChange = (field: string, e?: React.ChangeEvent<any>) => {
    if (!isUndefined(formik.errors[field as keyof typeof formik.errors])) {
      formik.setFieldError(field, undefined);
    }

    if (e) {
      formik.handleChange(e);
    }
  };

  const getFormTitle = () => (isEditMode ? t("editExistingVehicleAssignment") : t("assignVehicle"));
  const goToUploadFile = (): void => {
    setVehicleDragAndDropView(true);
  };
  const submitButtonText = () => {
    if (fleetManagerState.assignmentType === AssignmentType.Multiple) {
      return t("goToUpload");
    }

    return !isEditMode ? t("assignVehicle") : t("updateVehicle");
  };

  const hasNoCapacity = (): boolean => checkHasNoCapacity(parkingProducts.error);
  const hasNumberPlateAlreadyLinkedToAnotherUser = () => checkNumberPlateAlreadyAssigned(parkingProducts.error);
  const hasLicensePlateAlreadyLinked = () => checkLicensePlateAlreadyLinked(parkingProducts.error);
  const setAssignmentTypeSingle = (): void => setAssignmentType(AssignmentType.Single);
  const setAssignmentTypeMultiple = (): void => setAssignmentType(AssignmentType.Multiple);

  const handleNumberPlatePrefixChange = (npPrefix: string) => {
    onInputChange(Fields.NumberPlatePrefix);
    formik.setFieldValue(Fields.NumberPlatePrefix, npPrefix);
    validateNumberPlateAndSetState(npPrefix);
  };

  const oldNpValue = React.useRef("");
  const handleNumberPlateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const np = e.target.value;
    const field = Fields.NumberPlate;
    if (oldNpValue.current !== np) {
      formik.setFieldValue(field, np);
      onInputChange(field, e);
      oldNpValue.current = np;
    }
  };

  const tNumberPlate = () => {
    const nb = t("globals:numberPlate");

    if (appState.selectedLanguage === 'de') {
      return nb;
    }

    return nb.toLowerCase();
  }

  const validateGermanPlate = (numberPlate: string) : ValidationResult => {
    if (formik.values.numberPlate === numberPlate || formik.values.numberPlate.split('-')[0].length === 0) {
        return {
          status: 'error',
          message: format(t("errors:required.field"), tNumberPlate())
        };
    }

    if (!numberPlateValidator.isValidGermanRegionNumber(formik.values.numberPlate.split('-')[0])) {
        return {
          status: 'error',
          message: t("errors:format.numberPlate"),
        };
    }

    if(formik.values.numberPlate.split('-').pop()?.length === 0) {
      return {
        status: 'error',
        message: t("errors:format.numberPlate"),
      };
    }

    if (!numberPlateValidator.isValidGermanNumberPlate(formik.values.numberPlate)) {
        return {
          status: 'error',
          message: t("errors:format.numberPlate"),
        };
    }

    return { status: 'valid' };
  };

  const validateOtherNumberPlate = (numberPlate: string) : ValidationResult => {
    if (numberPlate.length === 0) {
      return {
        status: 'error',
        message: format(t("errors:required.field"), tNumberPlate()),
      };
    }

    if (!numberPlateValidator.isValidNumberPlate(numberPlate)) {
      return {
        status: 'warning',
        message: t("errors:format.numberPlate")
      };
    }

    return { status: 'valid' };
  }

  const getNumberPlate = (prefix: string) => {
    return prefix === 'D'
    ? formik.values.numberPlate.split('-').pop()! :
    formik.values.numberPlate.replace('-', '');
  }

  const validateNumberPlate = (numberPlatePrefix?: string) : ValidationResult | undefined => {
    let prefix = formik.values.numberPlatePrefix;

    if (numberPlatePrefix !== undefined) {
      prefix = numberPlatePrefix;

      if (formik.values.numberPlate.length === 0) {
        return;
      }
    }

    const numberPlate = getNumberPlate(prefix);

    if (prefix === 'D') {
      const germanResult = validateGermanPlate(numberPlate);
      if (germanResult.status !== 'valid') {
        return germanResult;
      }
    } else {
      const nbResult = validateOtherNumberPlate(numberPlate);
      if (nbResult.status !== 'valid') {
        return nbResult;
      }
    }

    if (numberPlate.length < 2) {
      return {
        status: 'warning',
        message: t("errors:minLength", { field: t("globals:numberPlate"), count: 2 })
      };
    }

    if (numberPlate.length > 7) {
      return {
        status: 'warning',
        message: t("errors:maxLength", { field: t("globals:numberPlate"), count: 7 }),
      };
    }

    return { status: 'valid' };
  };

  const setNumberPlateValidationResult = (result: ValidationResult) => {
    switch (result.status) {
      case 'error':
        setNrPlateError(result.message);
        break;
      case 'warning':
        setNrPlateWarning(result.message);
        break;
      case 'valid':
        setNumberPlateWarning(undefined);
        setNumberPlateError(undefined);
        break;
    }
  }

  const validateNumberPlateAndSetState = (numberPlatePrefix?: string) : ValidationResult | undefined => {
    const result = validateNumberPlate(numberPlatePrefix);
    if (!result) {
      return;
    }
    setNumberPlateValidationResult(result);
    return result;
  }

  const setNrPlateWarning = (warning: string) => {
    setNumberPlateError(undefined);
    setNumberPlateWarning(warning);
  }

  const setNrPlateError = (error: string) => {
    setNumberPlateWarning(undefined);
    setNumberPlateError(error);
  }

  const initiallySelectedPmcIds = useMemo(
    () =>
      isEditMode
        ? parkingProducts.selectedVehicleAssignment.data?.assignedProducts.map(p => p.pmcId)
        : [],
    [isEditMode, parkingProducts.selectedVehicleAssignment]
  );
 
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={formik.initialValues}
      onSubmit={handleFormSubmit}
    >
        <div className="form-container">
          <div className="form-header">
            <Container className="form-header-content">
              {isEditMode && (
                <DeleteWrapper onClick={onDeleteVehicleAssignment}>
                  <DeleteDelegeeIcon />
                  <DeleteText>{t("deleteVehicle")}</DeleteText>
                </DeleteWrapper>
              )}
              <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                {getFormTitle()}
              </Heading>
              <Button onClick={onClose} classes={{ root: "close-icon-btn" }} disableRipple>
                <CloseOutlined classes={{ root: "close-icon" }} />
              </Button>
            </Container>
          </div>
          <Box component="form" sx={{ flexGrow: 1, paddingY: 2 }}>
            {!isEditMode && !parkingProducts.hasUnlimitedEntryRights && useMultipleVehicleAssignments && (
              <Container>
                <NoPaddingBottomGrid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <NoPaddingBottomGrid container item direction="column" xs={12}>
                    <div>
                      <div>
                        <ButtonGroup variant="contained" fullWidth disableRipple>
                          <Button
                            onClick={setAssignmentTypeSingle}
                            color={
                              fleetManagerState.assignmentType === AssignmentType.Single
                                ? "primary"
                                : "default"
                            }
                            disableElevation
                          >
                            {t("single")}
                          </Button>
                          <Button
                            onClick={setAssignmentTypeMultiple}
                            color={
                              fleetManagerState.assignmentType === AssignmentType.Multiple
                                ? "primary"
                                : "default"
                            }
                            disableElevation
                          >
                            {t("multiple")}
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </NoPaddingBottomGrid>
                </NoPaddingBottomGrid>
              </Container>
            )}
            <div className="vehicle-form-body">
              <Container>
                {fleetManagerState.assignmentType == AssignmentType.Single && (
                  <React.Fragment>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Heading fontSize={typography.fontSizeBase}>
                        <strong>{t("accessInformation")}</strong>
                      </Heading>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Grid item container direction="column" xs={3}>
                        <RequiredInputLabel htmlFor={Fields.NumberPlate}>{t("globals:numberPlate")}</RequiredInputLabel>
                      </Grid>
                      <Grid item container direction="column" xs={9}>
                        {isEditMode ? fleetManagerState.selectedVehicleAssignment?.numberPlate :
                          <NumberPlateDeSupport
                            id={Fields.NumberPlate}
                            name={Fields.NumberPlate}
                            placeholder={t("visitors:visitorDetailsForm.numberPlatePlaceholder")}
                            regionPlaceholder={t("visitors:visitorDetailsForm.numberPlateRegionPlaceholder")}
                            onValueChange={handleNumberPlateChange}
                            validateNumberPlate={validateNumberPlateAndSetState}
                            onPrefixSelect={handleNumberPlatePrefixChange}
                            error={fleetManagerState.numberPlateError}
                            warning={fleetManagerState.numberPlateWarning}
                            value={formik.values.numberPlate}
                            prefix={formik.values.numberPlatePrefix}
                            numberToSkipOnKeyPress={topCountriesNumberPlatesDVA.length - 1}
                            supportedNumberPlates={supportedNumberPlates.map((s) => ({
                              numberPlatePrefix: s.numberPlateCode,
                              countryCode: s.countryCode,
                            }))}
                            setFieldError={formik.setFieldError}
                            setFieldValue={formik.setFieldValue}
                        />}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Grid container direction="column" xs={3} item>
                        <InputLabel htmlFor={Fields.Description}>{t("description")}</InputLabel>
                      </Grid>
                      <Grid container direction="column" xs={9} item>
                        <CustomInput
                          id={Fields.Description}
                          name={Fields.Description}
                          autoComplete="off"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          classes={{ root: "form-input" }}
                          variant="outlined"
                          placeholder={t("descriptionPlaceholder")}
                          error={Boolean(formik.errors.description)}
                        />
                        <MuiHelperText error={formik.errors.description} />
                      </Grid>
                    </Grid>
                    <Spacer />
                  </React.Fragment>
                )}
                <Translation ns={["vehicleForm", "delegeeForm"]} nsMode="fallback">
                  {(t) => (
                    <ParkingRightsGrid
                      data={parkingProducts.parkingProducts.data!}
                      initiallySelectedPmcIds={initiallySelectedPmcIds}
                      loading={parkingProducts.parkingProducts.loading || (isEditMode ? parkingProducts.selectedVehicleAssignment.loading : false)}
                      onChange={(event) => handleProductChange(event, formik.values.products ?? [])}
                      onStateChange={prs => handleSelectAll(prs.filter(pr => pr.checked).map(pr => pr.right?.pmcId!))}
                      onSelectAll={(ids) => handleSelectAll(ids)}
                      onDeselectAll={handleDeselectAll}
                      error={{
                        hasError: Boolean(formik.errors.products),
                        message: formik.errors.products,
                      }}
                      title={t("selectProduct")}
                      required={!isEditMode}
                      selectAllTooltipText={t("selectTooltip")}
                      deselectAllTooltipText={t("deselectTooltip")}
                      selectAllText={t("selectAll")}
                      deselectAllText={t("deselectAll")}
                    />
                  )}
                </Translation>
              </Container>
            </div>
          </Box>
          <CustomError show={hasNoCapacity()} message={t("errors:noCapacity")} />
          <CustomError show={hasNumberPlateAlreadyLinkedToAnotherUser()} message={t("errors:alreadyLinkedToAnotherUser")} />
          <CustomError show={hasLicensePlateAlreadyLinked()} message={t("errors:licensePlateAlreadyLinked")} />
          <div className="form-footer">
            <Container className="form-footer-content">
              <React.Fragment>
                <CustomButton.Light onClick={onClose} text={t("globals:close")} id="close-invite" />
                <CustomButton.Primary
                  loading={parkingProducts.loading}
                  onClick={(e) => {
                    e?.preventDefault();
                    if (fleetManagerState.assignmentType === AssignmentType.Multiple) {
                      formik.handleSubmit();
                      return;
                    }
                    if (fleetManagerState.numberPlateError) {
                      formik.validateForm();
                      return;
                    }
                    const res = validateNumberPlateAndSetState();
                    if (res !== undefined && res.status === 'error') {
                      formik.validateForm();
                      return;
                    }
                    formik.handleSubmit();
                  }}
                  text={submitButtonText()}
                  id="submit-invite"
                />
              </React.Fragment>
            </Container>
          </div>
        </div>
    </Formik>
  );
};

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts } = state;

  return {
    parkingProducts
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  disposeError: () => dispatch(parkingProductsActions.disposeError()),
  getParkingProducts: (seasonTicketOwnerCrmId: string, location: string,) => dispatch(parkingProductsActions.getParkingProducts(seasonTicketOwnerCrmId, location)),
  getVehicleAssignment: (aggregateId: string, onSuccess?: (res: any) => void) => dispatch(parkingProductsActions.getVehicleAssignment(aggregateId, onSuccess)),
  assignVehicle: (seasonTicketOwnerCrmId: string, req: AssignVehicleRequestModel, onSuccess?: () => void) => dispatch(parkingProductsActions.assignVehicle(seasonTicketOwnerCrmId, req, onSuccess)),
  updateVehicleAssignment: (seasonTicketOwnerCrmId: string, aggregateId: string, req: UpdateVehicleRequestModel, onSuccess?: () => void) => dispatch(parkingProductsActions.updateVehicleAssignment(seasonTicketOwnerCrmId, aggregateId, req, onSuccess)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VehicleForm);
