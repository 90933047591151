import React, { useState, useEffect } from "react";
import { Grid, FormHelperText } from "@material-ui/core";
import { variables } from "../../../theme/variables";
import { ParkingProductModel } from "../../../models/ParkingProductModel";
import Heading from "../../../ui/Heading/Heading";
import { isEmpty } from "underscore";
import ToggleSelectAll from "../ToggleSelectAll/ToggleSelectAll";
import ResultsGrid from "./ResultsGrid/ResultsGrid";
import "./_parkingRights.scss";
import { selectUseBulkVehicleAssignments, useGetOccupiedParkingRightsCount } from "../../../containers/FleetManager/MyProducts/ParkingRightsDataTable/common";
import { isNil } from "lodash";
import { useAppSelector } from "../../../store";

const { typography } = variables;

type ErrorType = {
  hasError: boolean;
  message: string | string[] | undefined;
};

const initialState: ICheckParkingRight[] = [];

export interface IParkingRightsGridProps {
  data: ParkingProductModel[] | null;
  initiallySelectedPmcIds?: number[];
  loading?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStateChange?: (state: typeof initialState) => void;
  onSelectAll: (ids: number[]) => void;
  onDeselectAll: () => void;
  error?: ErrorType;
  title: string;
  required: boolean;
  selectAllTooltipText: string;
  deselectAllTooltipText: string;
  selectAllText: string;
  deselectAllText: string;
}

export type ICheckParkingRight = {
  id: number;
  right?: ParkingProductModel;
  checked: boolean;
  disabled: boolean;
};

function isChecked(right: ParkingProductModel, selectedPmcIds?: number[]): boolean {
  return selectedPmcIds?.some((pmcId) => right.pmcId === pmcId) ?? false;
}

const ParkingRightsGrid = (props: IParkingRightsGridProps) => {
  const [state, setState] = useState(initialState);

  const getTotalOccupiedParkingRights = useGetOccupiedParkingRightsCount();
  const useBulkVehicleAssignments = useAppSelector(selectUseBulkVehicleAssignments);

  const onChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    const stateCopyShallow = [...state];
    const parsedId = parseInt(id);
    const foundIndex = stateCopyShallow.findIndex((x) => x.id === parsedId);

    if (foundIndex !== -1) {
      const { checked } = event.currentTarget;

      stateCopyShallow[foundIndex] = { ...stateCopyShallow[foundIndex], checked };
    }

    setState(stateCopyShallow);
    props.onChange(event);
  };

  const onSelectAll = () => {
    const newState = state.map((pr) => {
      if (pr?.checked) {
        return pr;
      }

      if (!pr?.disabled) {
        return {
          ...pr,
          checked: true,
        };
      }

      return pr;
    });

    props.onSelectAll(newState.filter((pr) => pr.checked).map((pr) => pr.right?.pmcId!));

    setState(newState);
  };

  const onDeselectAll = () => {
    setState((state) =>
      state.map((pr) => {
        if (!pr.checked) {
          return pr;
        }

        return { ...pr, checked: false };
      })
    );
    props.onDeselectAll();
  };

  const isParkingRightDisabled = (
    parkingProductModel: ParkingProductModel,
    isAssignedOrDelegated: boolean
  ): boolean => {
    if (useBulkVehicleAssignments && parkingProductModel?.hasUnlimitedEntryRights) {
      return false;
    }
    if (
      parkingProductModel?.totalDelegatedParkingRights !== undefined &&
      parkingProductModel?.totalParkingRights !== undefined
    ) {
      return (
        getTotalOccupiedParkingRights(parkingProductModel) >=
        parkingProductModel.totalParkingRights && !isAssignedOrDelegated
      );
    }

    return true;
  };

  useEffect(() => {
    if (!isNil(props.data) && !isEmpty(props.data)) {
      setState(
        props.data.map((right) => {
          const checked = isChecked(right, props.initiallySelectedPmcIds);
          const disabled = isParkingRightDisabled(right, checked);
          return {
            id: right.pmcId ?? 0,
            right,
            checked,
            disabled,
          };
        })
      );
    }

    return () => setState(initialState);
    // eslint-disable-next-line
  }, [props.data, props.initiallySelectedPmcIds]);

  useEffect(() => {
    if (props.onStateChange) {
      props.onStateChange(state);
    }
  }, [state]);

  const showSelectAll = () =>
    state.every((parkingRight) => parkingRight.checked || parkingRight.disabled);

  return (
    <Grid container direction="row" classes={{ root: "parking-rights-wrapper" }}>
      <Grid container item direction="column" xs={6}>
        <Heading required={props.required} fontSize={typography.fontSizeBase}>
          {props.title}
        </Heading>
      </Grid>
      <Grid container item direction="column" xs={6} alignItems="flex-end">
        <ToggleSelectAll
          showSelectAll={showSelectAll()}
          selectAllTooltipText={props.selectAllTooltipText}
          deselectAllTooltipText={props.deselectAllTooltipText}
          selectAllText={props.selectAllText}
          deselectAllText={props.deselectAllText}
          onSelectAll={onSelectAll}
          onDeselectAll={onDeselectAll}
        />
      </Grid>
      <Grid container item direction="column" xs={12}>
        <FormHelperText error={props.error?.hasError}>{props.error?.message}</FormHelperText>
      </Grid>

      <ResultsGrid loading={props.loading} state={state} onChecked={onChecked} />
    </Grid>
  );
};

export default ParkingRightsGrid;
