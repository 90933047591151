import { Box, Container, Grid, LinearProgress, Typography } from "@material-ui/core";
import React, { Dispatch, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import CircleImage from "../../../ui/CircleImage/CircleImage";
import XlsFileLoading from "../../../assets/images/xls-file-loading.svg"
import WarningFleet from "../../../assets/images/warning-fleet.svg"
import { NoPaddingBottomGrid } from "../../DataTable/DataTableStyledComponents";
import Heading from "../../../ui/Heading/Heading";
import { variables } from "../../../theme/variables";
import "../../../scss/_bulkProgressView.scss"
import parkingProductsActions from "../../../store/actions/parkingProducts.actions";
import { connect } from "react-redux";
import { RootReducer } from "../../../store/reducers";
import useAppContext from "../../../context/hooks/useAppContext";
import { EnvironmentState } from "../../../store/reducers/environment.reducer";
import DownloadDelegeeFile from "../../DownloadFile/DownloadDelegeeFile";
import { DelegeeFileType } from "../../DownloadFile/DelegeeFileType.enum";


const BulkDelegationView: React.FC<BulkDelegationViewProps> = ({
  parkingProducts,
  bulkInvitationPollingIntervalInSeconds,
  getBulkImportProgress
}) => {
  const { t } = useTranslation(["delegeeForm"]);
  const { typography } = variables;
  const { bulkImportProgress } = parkingProducts;
  const { appState } = useAppContext();

  const bulkUploadIsFinished = () => {
    return bulkImportProgress.progress === 100;
  }

  const bulkUploadHasErrors = () => {
    return bulkUploadIsFinished() && bulkImportProgress.hasErrors;
  }

  const pollBulkImportProgress = () => {
    setTimeout(() => {
      getBulkImportProgress(appState.user.seasonTicketOwnerCrmId!, parkingProducts.validateDelegees.fileReference!, pollBulkImportProgress)
    }, bulkInvitationPollingIntervalInSeconds * 1000)
  }

  useEffect(() => {    
    pollBulkImportProgress();
  }, [])  

  return (
    <Container className="h-full">
      { bulkUploadHasErrors() ? (
        <div>
          <NoPaddingBottomGrid
            container
            item
            direction="column"
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <div className="img-wrapper">
              <CircleImage src={WarningFleet} />
            </div>
          </NoPaddingBottomGrid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            alignItems="center"
            justifyContent="center"
            className="p-t-2"
          >
            <div className="dnd-file-choose-label">
              <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                {t("failedInvitationsResultHeader")}
              </Heading>
              <span className="text-dark-gray-2nd text-center d-flex">
                <Trans 
                  i18nKey="delegeeForm:failedInvitationsResultMessage" 
                  values={{ errorsCount: bulkImportProgress.numberOfErrors}}
                  components={{ br: <br /> }} />
              </span>
              <span className="text-dark-gray-2nd download-file-btn">
                <DownloadDelegeeFile
                  delegeeFileType={DelegeeFileType.Invitation}
                  seasonTicketOwnerCrmId={appState.user.seasonTicketOwnerCrmId!}
                  fileReference={parkingProducts.validateDelegees.fileReference!}
                  language={appState.selectedLanguage}
                />
              </span>
            </div>
          </Grid>
        </div>
      ) : (
        <div>
          <NoPaddingBottomGrid
            container
            item
            direction="column"
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <div className="img-wrapper">
              <CircleImage src={XlsFileLoading} />
            </div>
          </NoPaddingBottomGrid>
          <Grid
            container
            item
            direction="column"
            xs={12}
            alignItems="center"
            justifyContent="center"
            className="p-t-2"
          >
            <div className="dnd-file-choose-label">
              <Heading width="auto" justifyContent="center" fontSize={typography.fontSizeLarge}>
                {bulkUploadIsFinished() ? t("allInvitationsSent") : t("sendInvitationsInProgress")}
              </Heading>
              <span className="text-dark-gray-2nd text-center d-flex">           
                {
                  bulkUploadIsFinished() ? 
                    (<Trans i18nKey="delegeeForm:allInvitationsSentMessage" components={{ br: <br /> }} />) : 
                    (<Trans i18nKey="delegeeForm:sendingInvitationsMessage" components={{ br: <br /> }} />)
                } 
              </span>
            </div>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ width: '50%', mr: 1 }}>
              <LinearProgress style={{height: '8px', borderRadius:'4px'}} variant="determinate" value={bulkImportProgress.progress}/>
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2">{`${Math.round(bulkImportProgress.progress)}%`}</Typography>
            </Box>
          </Box>
        </div>
      ) }
    </Container>
  );
};

const getDynamicConfigurationAsNumber = (name: string, environment: EnvironmentState, defaultValue: number): number => {
  const stringValue = environment.dynamicConfigurations?.find(
    (item) => item.name === name
  )?.value;

  return stringValue === undefined ? defaultValue : parseInt(stringValue, 10);
}

interface BulkDelegationViewProps extends StateProps, DispatchProps {} 

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: RootReducer) => {
  const { parkingProducts, environment } = state;
  const bulkInvitationPollingIntervalInSeconds = getDynamicConfigurationAsNumber("BulkInvitationPollingIntervalInSeconds", environment, 5);

  return {
    parkingProducts,
    bulkInvitationPollingIntervalInSeconds
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getBulkImportProgress: (
    seasonTicketOwnerCrmId: string,
    fileReference: string,    
    pollBulkImportProgress: () => void
  ) =>
    dispatch(
      parkingProductsActions.getBulkImportProgress(
        seasonTicketOwnerCrmId,
        fileReference,
        pollBulkImportProgress
      )
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDelegationView);
